import React, { useContext, useEffect, useState } from "react";
import Menu from "./Menu/Menu"
import Burger from "./Burger/Burger"
import { AuthContext } from "../../context/AuthContext";

const BurgerMenu = ({menu = []}) => {
    const [menuList, setMenuList]= useState([]);
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();
    const permissions = useContext(AuthContext).permissions;
  
    useEffect(() => {
      if (permissions) {
        const adminAccess = permissions.admin?.access;
        const customerAccess = permissions.customer?.access;
  
        if (adminAccess) {
          const adminUrls = [];
          const mainPath = "admin/";
  
          Object.entries(permissions["admin"].modules).forEach(([key, value]) => {
            if (value.access) {
              const name = key;
              const url = mainPath + key.toLowerCase();
              adminUrls.push({ name, url });
            }
          });
  
          setMenuList(prev => [ ...adminUrls]);
        }
          
        if (customerAccess) {
          const userUrls = [];
          const mainPath = "user/";
  
          Object.entries(permissions["customer"].modules).forEach(([key, value]) => {
            if (value.access && key != "users") {
              const name = key;
              const url = mainPath + key.toLowerCase();
              userUrls.push({ name, url });
            }
          });
  
          setMenuList(prev => [ ...userUrls]);
        }
      }
    }, [permissions]);

    return (
        <>
            <div ref={node}
             onMouseLeave={() => setOpen(false)}>
                <Burger open={open} setOpen={setOpen}/>
                <Menu open={open} setOpen={setOpen} menuList={menu.length > 0 ? menu : menuList}/>
            </div>
        </>
    );
};

export default BurgerMenu;