import React, { useEffect, useState } from 'react';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import StatusesForm from '../../../../components/Forms/StatusesForm/StatusesForm'; // Assuming CategoriesForm is a separate component
import { useSelector } from "react-redux"
import { create, update } from '../../../../http/statusesAPI';
import { getStatusSelector } from "../../../../store/statuses/selectors";

const FormWrapper = ({ editStatus, createStatus, id, close}) => {
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [initialValues, setInitialValues] = useState({
        name: "",
        type: "",
        color: "#ffffff",
        active: "",
    });
    const editValuess = useSelector(getStatusSelector);

    const onSubmit = async (values) => {
        try {
            if (createStatus) {
                create(values).then(res => messageSuccess(res.data.message));
                close(true);
            }
            if (editStatus) {
                update(id,values).then(res => messageSuccess(res.data.message));
                close(true);
            }
        } catch (e) {
            messageError(e);
        }
    };

    return (
        <div>
            <StatusesForm initialValues={initialValues} onSubmit={onSubmit} edit={editStatus} create={createStatus} editValuess={editValuess}/>
        </div>
    );
};

export default FormWrapper;
