import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from '../../Container/Container';
import ErrorGif from './Error500.gif';
import styles from './Error500.module.scss';

const Error500 = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/index');
    window.location.reload();
  };

  return (
    <div className={styles.center}>
      <section className={styles.page_404}>
        <Container>
          <h1 className={styles.title}>Виникла помилка</h1>
        </Container>
        <div className={styles.fourZeroFourBg} style={{ backgroundImage: `url(${ErrorGif})` }}>
          {}
        </div>
        <Container>
          <div className={styles.contant_box_404}>
            <h3 className={styles.h2}>Ми все виправимо!</h3>

            <p className={styles.p}>Наша команда вже працює над цією проблемою</p>

            <Button variant="dark" onClick={() => goToHome()}>На головну</Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Error500;
