import { wishListLoadingAction, saveWishListAction } from './actions';
import { getWishlist, updateWishlist } from '../../http/wishlistAPI';
// import { getProductById } from '../../http/productAPI';
export const getWishListOperation = ( admin ) => async (dispatch) => {
  try {
    dispatch(wishListLoadingAction(true));
    getWishlist(admin).then(res => {
      dispatch(saveWishListAction(res.data.data));
      dispatch(wishListLoadingAction(false));
    })
  } catch (error) {
    console.error('Error fetching wishlist:', error);
  }
};
// export const getWishListOperation = () => async(dispatch) => {

  // try {
  //   dispatch(wishListLoadingAction(true));
  //   const data = await request('admin/wishlist/getall', 'GET');
  //   await dispatch(saveWishListAction(data.data));
  //   await dispatch(wishListLoadingAction(false));
  // } catch (error) {
  //     messageError(error);
  // }
// };

// export const addProductToWishlistOperation = id => dispatch => {
//   addProductToWishlist(id).then(res => {
//     dispatch(saveWishListAction(res.data));
//   });
// };

// export const deleteProductFromWishlishtOperation = (id, wishList) => dispatch => {
//   deleteProductFromWishlist(id).then(res => {
//     if (wishList.products.length === 1) {
//       dispatch(saveWishListAction(null));
//     } else {
//       dispatch(saveWishListAction(res.data));
//     }
//   });
// };

// export const deleteWishListOperation = () => dispatch => {
//   dispatch(saveWishListAction(null));
//   deleteWishlist().then(res => {
//     return res;
//   });
// };

export const updateWishListOperation = (admin, prod) => dispatch => {
  dispatch(wishListLoadingAction(true));
  let dbWishList = [];
  let wishList = { products: [] };
  const storageWishList = prod.products;
  const storageId = storageWishList.map(itemData => itemData.id);
  getWishlist(admin).then(res => {
    if (res.data && res.data.products) {
      dbWishList = res.data.products.map(item => {
        return item['_id'];
      });

      if (storageWishList) {
        const newItem = storageId.filter(id => !dbWishList.includes(id));
        wishList.products = dbWishList.concat(newItem);
        updateWishlist(admin, wishList).then(products => {
          dispatch(saveWishListAction(products.data));
          return products;
        });
      }
    } else {
      updateWishlist(admin, wishList).then(products => {
        dispatch(saveWishListAction(products.data));
        return res;
      });
    }
  });
};

// export const setFavForCustomerOperation = (itemNo, wishList) => dispatch => {
//   getProductById(itemNo).then(product => {
//     const { data } = product;
//     dispatch(saveWishListAction([...wishList, data]));
//   });
// };
