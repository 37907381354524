import React, { useContext, useEffect, useState } from 'react';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import ProductForm from '../../../../components/Forms/ProductForm/ProductForm'; // Assuming CategoriesForm is a separate component
import axios from 'axios';
import { AuthContext } from '../../../../context/AuthContext';

const Product = ({categories, brands, edit, create, id, close}) => {
    const auth = useContext(AuthContext);
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [countrys, setCountrys] = useState([]);
    const [initialValues, setInitialValues] = useState({
        enabled: true,
        name: "",
        description: "",
        imageFirst: "",
        currentPrice: 0,
        previousPrice: 0,
        categories: "",
        imageUrls: [],
        quantity: 0,
        sizes: "",
        productUrl: "",
        brand: "",
        manufacturer: "",
        manufacturerCountry: "",
        seller: "",
        discount: 0,
        bottling: false,
        quantityMl: 0,
        toCarusel: false,
    });
    const getCountys = async() => {
        const data = await request('admin/configs/get/coutrys', 'GET');
        setCountrys(data.data);
    }
    const getById = async () => {
        try {
            const data = await request(`admin/products/adminpanel/get/${id}`, "GET");
            data.imageUrls = data.imageFirst;
            setInitialValues(data);
            messageSuccess(data.message);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (edit) getById();
        getCountys();
    }, [edit]);

    const onSubmit = async (values) => {      
        try {
            const config = {}
            if(auth.isAuthenticated){
                config.headers = {"Authorization": `Bearer ${auth.token}`}
              }
            if (create) {
                // const data = await request(`admin/products/create`, "POST",  { ...values } );
                const data = axios.post("/api/admin/products/create", values, config)
                .then(res => {
                    res.data.status ? close(true) : close(false)
                })
                .catch(er => messageError(er.response.data.message))
                messageSuccess(data.message);
            }

            if (edit) {
                // const data = await request(`admin/products/edit/${id}`, "PUT", { ...values });
                const data = axios.put(`/api/admin/products/edit/${id}`, values, config)
                .then(res => {res.data.status ? close(true) : close(false)})
                .catch(er => messageError(er.response.data.message))
                messageSuccess(data.message);
                close(true);
            }
        } catch (e) {
            messageError(e);
        }
    };

    return (
        <div>
            <ProductForm edit={edit} initialValues={initialValues} onSubmit={onSubmit} categoriess={categories} brands={brands} countrys={countrys}/>
        </div>
    );
};

export default Product;
