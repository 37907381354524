import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
// import Form from './Form/Form';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import Container from '../../../components/Container/Container';
import { useNavigate } from 'react-router-dom';

const Roles = () => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();

    const fetchAll = async () => {
        try {
            const data = await request('admin/roles/get', 'GET');
            setRoles(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        fetchAll();
    }, []);

    const handleDelete = async (id) => {
        try {
            await request(`admin/roles/delete/${id}`, 'DELETE');
            messageSuccess('Видалено успішно');
            fetchAll();
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        navigate("form")
    };
    const handleEdit = (id) => {
        navigate(`form/${id}`)
    };

    return (
            <Container>
                <div>
                    <BurgerMenu />
                    <h2>Всі ролі</h2>
                    <div className='d-flex justify-content-end'>
                        <Button variant="dark" className='mb-3' onClick={() => handleCreate()}>Створення</Button>
                    </div>
                    <div className='table__wrapper'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Назва</th>
                                    <th>Опис</th>
                                    <th>Взаємодія</th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles.map((role, index) => (
                                    <tr key={role._id}>
                                        <td>{index + 1}</td>
                                        <td>{role.name}</td>
                                        <td>{role.description}</td>
                                        <td>
                                            <Button variant="secondary" onClick={() => handleEdit(role._id)}>Редагування</Button>{' '}
                                            <Button variant="danger" onClick={() => handleDelete(role._id)}>Видалення</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
    );
};

export default Roles;