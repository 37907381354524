import * as Yup from 'yup';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Це занадто коротке ім'я")
    .max(60, 'Мабуть, вас так не звуть')
    .required("Вкажіть ваше ім'я"),
  lastName: Yup.string()
    .min(2, 'Вибачте, це занадто коротке для прізвища')
    .max(60, 'Можливо, трохи скоротити?')
    .required('Вкажіть ваше прізвище'),
  phone: Yup.string()
    .matches(/^\+380\d{9}$/, 'Номер телефону, повинен бути у форматі +380XXXXXXXXX')
    .required('Вкажіть номер телефону'),
  email: Yup.string()
    .email('Невірна адреса електронної пошти')
    .required('Вкажіть електронну пошту'),
  region: Yup.string()
    .min(3, 'Оберіть область')
    .max(50, 'Макс. 50 літер')
    .required('Вкажіть область'),
  city: Yup.string()
    .min(3, 'Оберіть місто')
    .max(100, 'Макс. 100 літер')
    .required('Вкажіть місто'),
  delivery: Yup.string()
    .min(3, 'Оберіть спосіб доставки')
    .max(40, 'Макс. 40 літер')
    .required('Вкажіть спосіб доставки'),
  address: Yup.string()
    .required('Вкажіть адресу'),
  paymentMethod: Yup.string()
    .max(60, 'Макс. 60 літер')
    .required('Вкажіть спосіб оплати'),
  comment: Yup.string()
    .max(400, 'Макс. 400 літер'),
});

export default schema;
