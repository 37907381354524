/* eslint-disable max-len */
import React from 'react';

export const twitter = (color = 'rgba(125,125,125, 0.5)', filled = true, width = 25, height = 25) => (
  <svg width={width} height={height} viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64 12.664a26.747 26.747 0 0 1-7.542 2.014c2.714-1.582 4.795-4.09 5.773-7.076m0 0a26.708 26.708 0 0 1-8.336 3.104c-2.396-2.484-5.81-4.039-9.586-4.039-7.25 0-13.13 5.727-13.13 12.793 0 1 .117 1.977.341 2.914-10.911-.536-20.587-5.626-27.064-13.366a12.47 12.47 0 0 0-1.776 6.43c0 4.439 2.315 8.354 5.839 10.648a13.358 13.358 0 0 1-5.945-1.602v.162c0 6.197 4.523 11.367 10.531 12.541a13.547 13.547 0 0 1-5.93.218c1.672 5.08 6.521 8.779 12.263 8.883-4.492 3.43-10.151 5.477-16.305 5.477A27.22 27.22 0 0 1 0 51.585a37.879 37.879 0 0 0 20.128 5.748c24.153 0 37.359-19.492 37.359-36.395 0-.555-.014-1.105-.037-1.658A26.168 26.168 0 0 0 64 12.663'
      fillRule='evenodd'
      clipRule='evenodd'
      fill={filled ? color : 'none'}
    />
  </svg>
);
