import React, { useState } from 'react';
import styles from './CarouselSettings.module.scss';
import axios from 'axios';

const UpdateCarouselForm = ({ carusels, selectedCaruselId, setSelectedCaruselId, onSuccess, config }) => {
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [active, setActive] = useState(false);

  const handleUpdate = async () => {
    if (!selectedCaruselId) return;
    try {
      await axios.put(`/api/admin/carusels/update/${selectedCaruselId}`, { title: updatedTitle, active }, config);
      onSuccess();
    } catch {
      alert('Помилка при оновленні каруселі');
    }
  };

  return (
    <div className={styles.updateCarusel}>
      <h2>Оновити карусель</h2>
      <select
        value={selectedCaruselId}
        onChange={(e) => {
          const selected = carusels.find((c) => c._id === e.target.value);
          setSelectedCaruselId(e.target.value);
          setUpdatedTitle(selected?.title || '');
          setActive(selected?.active || false);
        }}
      >
        <option value="">Оберіть карусель</option>
        {carusels.map((carusel) => (
          <option key={carusel._id} value={carusel._id}>
            {carusel.title}
          </option>
        ))}
      </select>
      <input
        type="text"
        placeholder="Оновлена назва"
        value={updatedTitle}
        onChange={(e) => setUpdatedTitle(e.target.value)}
      />
      <label>
        <input
          type="checkbox"
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
        />
        Активувати
      </label>
      <button onClick={handleUpdate} className={styles.button}>Оновити</button>
    </div>
  );
};

export default UpdateCarouselForm;
