import { SET_FILTERS_PRODUCTS,SET_FILTERS_PRODUCTS_LOADER } from './types';

const initialState = {
  filtersProducts: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS_PRODUCTS: {
      return { ...state, filtersProducts: action.payload };
    }
    case SET_FILTERS_PRODUCTS_LOADER: {
      return { ...state, isLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
