import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styles from './MainCarusel.module.scss';
import { getCaruselMain } from '../../../http/caruselAPI';
import CarouselItemContent from './CarouselItemContent/CarouselItemContent';

const MainCarusel = () => {
  const [carousel, setCarousel] = useState([]);
  const [carouselSlides, setCarouselSlides] = useState([]);
  if (!localStorage.getItem('cart')) {
    localStorage.setItem('cart', JSON.stringify([]));
  }
  const getCarousel = async () => {
    try {
      const data = await getCaruselMain();
      if (data.data.status) {
        setCarousel(data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCarousel();
  }, []);

  useEffect(() => {
    if (carousel.length > 0) {
      const slides = carousel.map((item) => (
        <Carousel.Item key={item.order} className={styles.carouselItem}>  {/* Carousel.Item directly in the mapping */}
          <CarouselItemContent slide={item} />  {/* CarouselItem used here */}
        </Carousel.Item>
      ));
      setCarouselSlides(slides);
    }
  }, [carousel]);

  return (
    <Carousel fade indicators interval={3000} className={styles.mainCarousel}>
      {carouselSlides}
    </Carousel>
  );
};

export default MainCarusel;
