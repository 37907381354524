import {
  SET_PRODUCTS,
  SET_PRODUCTS_FILTERED,
  SET_PRODUCTS_LOADING,
  SET_ONE_PRODUCT,
  SET_ONE_PRODUCT_LOADING,
  SET_PRODUCTS_FILTER,
  SET_PRODUCTS_QUANTITY,
  SET_ADMIN_PRODUCTS,
  SET_ADMIN_ONE_PRODUCT,
  SET_ADMIN_PANEL_PRODUCTS,
  SET_ADMIN_PANEL_ONE_PRODUCT
} from './types';

export const saveProductsAction = products => ({ type: SET_PRODUCTS, payload: products });
export const saveProductsFilteredAction = products => ({ type: SET_PRODUCTS_FILTERED, payload: products });
export const saveOneProductAction = product => ({ type: SET_ONE_PRODUCT, payload: product });
export const saveProductsFilterAction = filter => ({ type: SET_PRODUCTS_FILTER, payload: filter });
export const saveProductsQuantityAction = quantity => ({ type: SET_PRODUCTS_QUANTITY, payload: quantity });
export const productsLoadingAction = isLoading => ({ type: SET_PRODUCTS_LOADING, payload: isLoading });
export const oneProductLoadingAction = isLoading => ({ type: SET_ONE_PRODUCT_LOADING, payload: isLoading });
export const saveAdminProductsAction = adminProducts => ({ type: SET_ADMIN_PRODUCTS, payload: adminProducts });
export const saveAdminPanelProductsAction = adminProducts => ({ type: SET_ADMIN_PANEL_PRODUCTS, payload: adminProducts });
export const saveAdminOneProductAction = product => ({ type: SET_ADMIN_ONE_PRODUCT, payload: product });
export const saveAdminPanelOneProductAction = product => ({ type: SET_ADMIN_PANEL_ONE_PRODUCT, payload: product });

// export const changeProductsStyle = viewType => ({ type: SET_PRODUCTS_STYLE, payload: viewType });
