export const contractData = [
    {
      id: "general-provisions",
      title: "1. Загальні положення",
      content: [
        "1.1. Даний договір публічної оферти є офіційною пропозицією ФОП Полтавець Марії Вікторівни (Україна, 39601, Полтавська обл., м. Кременчук, вул. Квартал 101, буд. 11, кв.3), далі – Продавець, укласти договір купівлі – продажу.",
        "1.2. Даний договір публічної оферти пропонує здійснити купівлю – продаж дистанційно, через Інтернет-магазин Продавця.",
        "1.3. Даний договір публічної оферти є загальнодоступним, тобто відповідно до статей 633, 641 Цивільного кодексу України його умови однакові для всіх Покупців незалежно від статусу (фізична особа, юридична особа, фізична особа — підприємець).",
        "1.4. Оскільки цей Договір є публічною офертою, то, отримуючи доступ до матеріалів Інтернет-магазину, Покупець вважається таким, що приєднався до цього Договору.",
        "1.5. Продавець має право в будь-який час в односторонньому порядку змінювати умови цього Договору. Новий або змінений Договір стає дійсним після розміщення у Інтернет-магазині. При незгоді Покупця з внесеними змінами він зобов'язаний відмовитися від доступу до Інтернет-магазину, припинити використання матеріалів і сервісів Інтернет-магазину.",
        "1.6. Покупець погоджується з цією публічною офертою в цілому і без застережень при оформленні Замовлення у Інтернет-магазині.",
        "1.7. Відвідувач / Покупець погоджується з тим, що всі умови цього Договору йому зрозумілі, і він приймає їх повністю і беззастережно.",
      ],
    },
    {
      id: "terms-and-definitions",
      title: "2. Терміни та визначення",
      content: [
        "«Договір публічної оферти» - публічний договір, зразок якого розміщений на Веб-сайті https://jado.in.ua/, і застосування якого є обов’язковим для всіх Покупців / Відвідувачів.",
        "«Інтернет – магазин» - відповідно до п.8 ч.1 ст. 3 Закону України «Про електронну комерцію» це засіб для представлення або реалізації товару, роботи чи послуги шляхом вчинення електронного правочину. Вживаючи даний термін, Сторони посилаються на веб – сайт https://jado.in.ua/",
        "«Акцепт» - прийняття Покупцем пропозиції Продавця щодо придбання Товару, зображення якого розміщено у Інтернет-магазині шляхом додавання його у віртуальний кошик та оформлення Замовлення з подальшою його оплатою.",
        "«Товар» - парфумерно - косметичні вироби щодо придбання яких у Інтернет-магазині розміщена пропозиція Продавця.",
        "«Замовлення» - належно оформлена та розміщена у Інтернет-магазині заявка Покупця на купівлю Товарів, що адресується Продавцю.",
        "«Відвідувач» - будь-яка дієздатна фізична особа, юридична особа, фізична особа – підприємець, що відвідали Інтернет-магазин, розміщений на сайті https://jado.in.ua/",
        "«Покупець» - будь-яка дієздатна фізична особа, юридична особа, фізична особа-підприємець, що відвідали Інтернет-магазин та має намір придбати той чи інший Товар.",
        "«Продавець» - фізична особа – підприємець Полтавець Марія Вікторівна, котра здійснює реалізацію товару у Інтернет – магазині, що розміщений на сайті https://jado.in.ua/",
      ],
    },
    {
      id: "subject-of-agreement",
      title: "3. Предмет договору",
      content: [
        "3.1. Продавець зобов’язується на умовах та в порядку, визначених цим Договором, продати Товар на основі Замовлення, оформленого Покупцем на відповідній сторінці Інтернет-магазину, а Покупець зобов’язується на умовах та в порядку, визначених цим Договором, придбати Товар і сплатити за нього кошти.",
        "3.2. Продавець гарантує, що Товар не переданий в заставу, не є предметом спору, не перебуває під арештом, а також на нього відсутні будь-які права третіх осіб.",
        "3.3. Продавець та Покупець підтверджують, що чинний Договір не є фіктивною чи удаваною угодою або угодою, укладеною під впливом тиску чи обману.",
        "3.4. Продавець підтверджує, що має всі необхідні дозволи на здійснення господарської діяльності, які регулюють сферу правовідносин, що виникають та діють в процесі виконання цього Договору, а також гарантує, що має право на реалізацію товару без будь-яких обмежень, у відповідності з вимогами діючого законодавства України та зобов’язується нести відповідальність у випадку порушення прав Покупця в процесі виконання цього Договору та реалізації Товару.",
        "3.5. Продавець не є виробником Товару та здійснює продаж Товарів вироблених іншими особами, перелік яких зазначений у відповідному розділі Інтернет-магазину та у описі кожної конкретної одинці Товару.",
      ],
    },
    {
      id: "order-procedure",
      title: "4. Порядок оформлення замовлення",
      content: [
        "4.1. Покупець самостійно оформлює Замовлення на відповідній сторінці Інтернет-магазину шляхом додавання Товарів у віртуальний кошик за допомогою натискання кнопки «До кошика» та «Оформити замовлення», або зробивши замовлення за номером телефону, або через повідомлення у соціальних мережах.",
        "4.2. Строк формування Замовлення до 2 робочих днів з моменту його оформлення. В разі, якщо Замовлення відправлено у вихідний або святковий день, строк формування починається з першого після вихідного робочого дня.",
        "4.3. При відсутності Товару Продавець зобов’язаний повідомити про це Покупця засобами зв’язку вказаними Покупцем в ході формування Замовлення.",
        "4.4. При відсутності товару Покупець має право замінити його іншим наявним Товаром, відмовитися від даної одинці Товару, анулювати Замовлення.",
        "4.6. Оформлюючи замовлення Покупець дає згоду Продавцю на отримання та обробку наступних персональних даних Покупця: Ім’я, Прізвище, По-батькові; адреса електронної пошти; номер телефону; адреса доставки замовлення.",
        "4.7. Для формування замовлень у Інтернет-магазині Покупець може пройти процедуру реєстрації та внести необхідні дані згідно з анкетою.",
        "4.8. При реєстрації кабінету у Інтернет-магазині Покупець зобов'язується надати достовірну і точну інформацію про себе і свої контактні дані для виконання Продавцем своїх зобов'язань перед Покупцем і доставки йому сплаченого Товару.",
      ],
    },
    {
        id: "price-and-payment",
        title: "5. Ціна договору та порядок оплати",
        content: [
          "5.1. Ціна кожного окремого Товару визначається Продавцем та зазначається на відповідній сторінці Інтернет-магазину. Загальна ціна Договору купівлі-продажу визначається шляхом додавання цін всіх обраних Товарів, поміщених у віртуальний кошик, та ціни доставки, яка визначається в залежності від способу доставки, обраного Покупцем.",
          "5.2. Вартість Замовлення може змінюватись в залежності від ціни, кількості чи номенклатури товару та обраного способу доставки.",
          "5.3. Відвідувач / Покупець погоджується з тим, що ціна на Товар, відкладений ним в «кошик», його асортимент і кількість будуть актуальними лише на момент формування «кошика» і можуть змінюватися, якщо покупка не була остаточно оформлена шляхом натискання кнопки «Оформити замовлення» та / або «Оплатити».",
          "5.4. Оплата Товару здійснюється на умовах передоплати шляхом банківського переказу онлайн на розрахунковий рахунок Покупця шляхом використання платіжних систем, вказаних у відповідному розділі Інтернет-магазину.",
          "5.5. При оплаті Покупцем замовлення платіжною карткою можливо стягування додаткової комісії емітентом цієї картки. При натисканні кнопки «Оформити замовлення» та / або «Оплатити», Покупець вважається повідомленим про можливість стягнення додаткової комісії при оплаті карткою відповідного банку.",
          "5.6. Продавець не несе відповідальності за роботу платіжної системи та / або банку в ході проведення оплати за Товар.",
        ],
      },
      {
        id: "delivery",
        title: "6. Спосіб доставки",
        content: [
          "6.1. Доставка Товару Покупцю здійснюється одним із доступних у Інтернет-магазині способів доставки за вибором самого Покупця, а саме службою доставки ТОВ «Нова Пошта» або АТ «Укрпошта».",
          "6.2. Доставка здійснюється до поштового відділення. За бажанням Покупця доставка може бути здійснена кур’єром, за наявності такої послуги у поштових компаній, перелічених у пункті 6.1. цього Договору.",
          "6.3. При доставці Товару Покупець у повному обсязі і беззастережно погоджується з правилами доставки посилок, що застосовуються обраною Покупцем поштовою компанією.",
          "6.4. Факт отримання Товару та відсутність претензій до якості Товару, який доставляється поштовими компаніями, Покупець підтверджує власним розписом у накладній про отримання Товару або іншому документі, що використовується поштовою компанією для підтвердження факту доставки посилки.",
          "6.5. У разі відсутності Покупця за адресою доставки, вказаної Покупцем у заявці, Товар повертається Продавцю.",
          "6.6. Терміни доставки Товару визначаються поштовою компанією. Продавець не несе відповідальності за затримки у доставці Товару, спричинені поштовою компанією.",
          "6.7. Ризик випадкового повного або часткового пошкодження Товару переходить до Покупця в момент передачі йому Товару і проставлення підпису в документах, що підтверджують його доставку.",
        ],
      },
      {
        id: "return-policy",
        title: "7. Умови повернення товару",
        content: [
          "7.1. Здійснюючи замовлення у Інтернет-магазині, Покупець підтверджує, що йому відомо про те, що відповідно до «Переліку товарів належної якості, що не підлягають обміну (поверненню)», що є Додатком №3 до Постанови Кабінету Міністрів України від 19.03.1994 №172, Товар, пропозиції щодо продажу якого розміщені у Інтернет-магазині, не підлягає обміну або поверненню, за умови що даний Товар є належної якості.",
          "7.2. Товар неналежної якості – це Товар, який містить недоліки. Недолік Товару – це невідповідність його вимогам нормативно-правових актів і нормативних документів, умовам Договору або вимогам, які пред'являються до нього. Такими недоліками можуть бути зокрема: пошкодження флакону, неробочий розпилювач.",
          "7.3. Відмінність елементів дизайну або оформлення від заявленого на Сайті опису не є ознакою неналежної якості.",
          "7.4. Якщо Покупцеві був переданий Товар неналежної якості, Покупець має право скористатися положеннями ст. 8 Закону України «Про захист прав споживачів» – вимагати заміни на Товар належної якості або вимагати розірвання Договору та повернення сплаченої за Товар грошової суми.",
          "7.5. Вимоги (заяву) про повернення грошової суми, сплаченої за Товар неналежної якості, підлягають задоволенню відповідно до вимог законодавства, починаючи з дня пред'явлення відповідної вимоги. Повернення грошових коштів здійснюється шляхом повернення вартості сплаченого Товару на платіжну карту Покупця.",
        ],
      },
      {
        id: "rights-and-obligations",
        title: "8. Права та обов’язки сторін",
        content: [
          "8.1. Продавець має право:",
          "- в односторонньому порядку припинити надання послуг за цим Договором у разі порушення Покупцем умов цього Договору;",
          "- на отримання оплати за придбаний Покупцем Товар.",
          "8.2. Продавець зобов’язаний:",
          "- продати Товар на умовах, передбачених цим Договором, відповідно до замовлення Покупця та обраного ним способу доставки.",
          "8.3. Покупець має право:",
          "- оформити замовлення в Інтернет-магазині;",
          "- вимагати від Продавця виконання умов цього Договору;",
          "- зареєструвати кабінет у Інтернет-магазині;",
          "- на обмін Товару неналежної якості.",
          "8.4. Покупець зобов’язаний:",
          "- своєчасно оплатити та отримати замовлення на умовах цього Договору;",
          "- дотримуватись умов цього Договору.",
        ],
      },
      {
        id: "liability",
        title: "9. Відповідальність Сторін",
        content: [
          "9.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього Договору в порядку, визначеному цим Договором та діючим законодавством України.",
          "9.2. Продавець не несе ніякої відповідальності за будь-які помилки, друкарські помилки і неточності, які можуть бути виявлені в матеріалах, що містяться на даному веб-сайті. Продавець докладає всіх необхідних зусиль, щоб забезпечити точність і достовірність інформації на сайті Інтернет-магазину.",
          "9.3. Продавець не несе ніякої відповідальності за висловлювання і думки Відвідувачів / Покупців Інтернет-магазину, залишені в якості коментарів або оглядів.",
          "9.4. Продавець не несе відповідальності за можливі протиправні дії Відвідувача / Покупця щодо третіх осіб, або третіх осіб щодо Відвідувача / Покупця.",
          "9.5. Продавець не несе відповідальності за шкоду, збитки або витрати (реальні або можливі), що виникли в зв'язку з Інтернет-магазином, його використанням або неможливістю використання.",
          "9.6. Продавець не несе відповідальності за втрату Відвідувачем / Покупцем можливості доступу до свого кабінету у Інтернет-магазині (втрату логіна, пароля, іншої інформації).",
          "9.7. З метою вищевикладеного Продавець залишає за собою право видаляти розміщену на Сайті інформацію і робити технічні і правові заходи для припинення доступу до Інтернет-магазину Відвідувачів / Покупців, що створюють проблеми у використанні Інтернет-магазину іншими Відвідувачами.",
        ],
      },
      {
        id: "force-majeure",
        title: "10. Форс-мажорні обставини",
        content: [
          "10.1. Сторони не несуть відповідальності за невиконання будь-якого зі своїх зобов'язань, за винятком зобов'язань по оплаті, якщо доведуть, що таке невиконання було викликано форс-мажорними обставинами.",
          "10.2. До форс-мажорних обставин належать, зокрема, природні катаклізми, страйки, пожежі, повені, вибухи, війни, заколоти, затримки перевізників, небезпеки і випадковості на морі, ембарго, катастрофи, обмеження, що накладаються державними органами.",
          "10.3. Час, який потрібен Сторонам для виконання своїх зобов'язань за цим Договором, буде продовжено на будь-який термін, протягом якого було відкладено виконання через форс-мажорні обставини.",
          "10.4. Якщо невиконання зобов’язань через форс-мажор триває більше трьох місяців, кожна із Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону.",
        ],
      },
      {
        id: "intellectual-property",
        title: "11. Виняткові права Продавця",
        content: [
          "11.1. Всі об'єкти, доступні за допомогою Інтернет-магазину, в тому числі елементи дизайну, текст, графічні зображення, ілюстрації, відео, бази даних, музика, звуки і інші об'єкти, є об'єктами виняткових прав Продавця та / або інших осіб.",
          "11.2. Використання контенту, а також будь-яких інших елементів сервісів можливо тільки в рамках функціоналу, пропонованого тим чи іншим сервісом.",
          "11.3. Використання Відвідувачем елементів змісту сервісів, а також будь-якого контенту для особистого некомерційного використання, допускається за умови збереження всіх знаків охорони авторського права.",
        ],
      },
      {
        id: "notifications",
        title: "12. Інформаційні повідомлення/Розсилка",
        content: [
          "12.1. Розсилка та інформаційні повідомлення будуть відправлені на адреси електронної пошти Покупців, які придбали Товар в Інтернет-магазині.",
          "12.2. Підставою для отримання розсилки та/або інформаційного повідомлення є згода Покупця, оформлена шляхом вказівки адреси електронної пошти та/або номера мобільного телефону.",
          "12.3. Покупець у будь-який момент може відписатися від отримання розсилки через відповідний функціонал в тілі листа розсилки.",
        ],
      },
      {
        id: "privacy-policy",
        title: "13. Умови конфіденційності",
        content: [
          "13.1. Умови конфіденційності визначені Політикою конфіденційності, що є невід’ємною частиною Договору.",
          "13.2. Політика конфіденційності поширюється на персональні дані та відомості, які збираються на Сайті. Ця Політика не поширюється на інші ресурси, посилання на які можуть бути вказані у Інтернет-магазині.",
          "13.3. Продавець використовує інформацію про Відвідувача / Покупця з метою виконання Замовлень.",
          "13.4. Заповнюючи реєстраційну анкету, Відвідувач (Покупець) добровільно дає згоду на збір і обробку своїх персональних даних Продавцем з подальшою метою: обробки Замовлень, отримання інформації про Замовлення, рекламних та спеціальних пропозицій.",
          "13.5. Покупець дає право Продавцю здійснювати обробку його персональних даних, зберігати ці дані та забезпечувати їх захист від несанкціонованого доступу третіх осіб.",
        ],
      },
      {
        id: "contract-duration",
        title: "14. Строк дії договору та порядок його розірвання",
        content: [
          "14.1. Цей Договір вступає в силу з моменту його акцепту Відвідувачем / Покупцем та діє до остаточного виконання Сторонами зобов’язань по Договору.",
          "14.2. Даний Договір може бути розірваний до моменту закінчення його дії в односторонньому порядку у випадках, передбачених умовами Договору.",
        ],
      },
  ];
  