import React from "react";
import styles from "./Index.module.scss";
import MainCarusel from "../../components/Carusel/MainCarusel/MainCarusel";

const Index = () => {
    return (
        <>
            <section className={styles.container}>
                <MainCarusel/>
            </section>
        </>
    );
};

export default Index;