import { Decimal } from 'decimal.js';
export const replace = num => {
  const str = num.toString();
  return str.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, `$1 `);
};

export const calculateTotalPrice = (cart) => {
  let totalPrice = new Decimal(0);

  // Якщо переданий масив продуктів напряму
  const products = cart?.products || cart;

  if (!products?.length) {
    return replace(totalPrice.toDecimalPlaces(2).toString());
  }
  if(products?.length){	
    products.forEach(p => {
      let price = new Decimal(p.currentPrice || p.product?.currentPrice || 0);
      price = new Decimal(p.product?.currentPrice || p.currentPrice || 0);
      let discount = new Decimal(0);

      // Перевіряємо знижку на рівні продукту
      if (p.product.discount && p.product.discount > 0) {
        discount = new Decimal(p.product.discount).div(100);
      } 

      // Перевіряємо знижку на рівні категорії
      else if (p.product.categories?.discount?.quantity && p.product.categories.discount.quantity > 0) {
        discount = new Decimal(p.product.categories.discount.quantity).div(100);
      }

      // Обчислюємо остаточну ціну зі знижкою (якщо вона є)
      let finalPrice = price.mul(p.cartQuantity).mul(new Decimal(1).minus(discount));

      // Додаємо вартість пляшок, якщо продукт має 'bottling'
      if (p.bottling && p.bottleCost?.bottles?.length) {
        // p.bottleCost.bottles.forEach((bottle) => {
          // const bottlePrice = new Decimal(bottle.price || 0).mul(bottle.count || 0);
          const bottlePrice = new Decimal(p.bottleCost.totalCost || 0);
          finalPrice = finalPrice.plus(bottlePrice);
        // });
      }

      // Додаємо до загальної суми
      totalPrice = totalPrice.plus(finalPrice);
    });
  }
  // Повертаємо загальну ціну, округлену до 2 знаків після коми
  return replace(totalPrice.toDecimalPlaces(2).toString());
};

export const getDate = date => {
  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const dateStr = new Date(date).toLocaleString('ua', optionsDate);
  return dateStr;
};
