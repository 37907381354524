import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

const ProductsGroupsForm = ({ initialValues, onSubmit, edit, create }) => {
    const [products, setProducts] = useState(initialValues.products || ['']); // Масив для зберігання _id продуктів

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Обов'язкове поле"),
            products: Yup.array()
                .of(Yup.string().required("Обов'язкове поле"))
                .required("Обов'язкове поле"),
        }),
        onSubmit: (values, { resetForm }) => {
            onSubmit({ ...values, products });
            resetForm();
            setProducts(['']);
        },
    });

    const handleProductChange = (index, value) => {
        const newProducts = [...products];
        newProducts[index] = value;
        setProducts(newProducts);
    };

    const handleAddProductInput = () => {
        setProducts([...products, '']);
    };

    const handleRemoveProductInput = (index) => {
        const newProducts = [...products];
        newProducts.splice(index, 1);
        setProducts(newProducts);
    };

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Назва</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </Form.Group>

                {products.map((productId, index) => (
                    
                    <Row key={index}>
                        <Col>
                            <Form.Group controlId={`products[${index}]`}>
                                <Form.Label>_id Продукту</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="_id продукту"
                                    name={`products[${index}]`}
                                    onChange={(e) => handleProductChange(index, e.target.value)}
                                    value={productId}
                                />
                            </Form.Group>
                        </Col>
                        <Col className='d-flex align-items-end'>
                            <Button variant="danger" onClick={() => handleRemoveProductInput(index)}>
                                Видалити
                            </Button>
                        </Col>
                    </Row>
                ))}
                <Form.Group controlId="name" className='mt-3'>
                    <Button variant="dark" type="button" onClick={handleAddProductInput}>
                        Додати поле
                    </Button>
                </Form.Group>

                <Button variant="dark" type="submit" className='mt-3'>
                    {create && "Створити"}
                    {edit && "Редагувати"}
                </Button>
            </Form>
        </div>
    );
};

export default ProductsGroupsForm;
