import React, { useEffect, useState } from "react";
import styles from "./MenuProducts.module.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/auth/isAuth";
import { useLogout } from "../../../utils/auth/logout";
import { getWishListSelector } from "../../../store/wishList/selectors";
import { getLocalCartSelector } from "../../../store/cart/selectors";
import { useSelector } from "react-redux";
import NavList from "../../header/NavList/NavList";
import useAuthLinks from "../../../utils/auth/authLinks";
import useIsMobile from "../../../utils/auth/useIsMobile";

const MenuProducts = ({ open, setOpen, menuList }) => {
  const [menuItems, setMenuItems] = useState([]);
  const auth = useAuth();
  const logout = useLogout();
  const wishlist = useSelector(getWishListSelector);
  const localCart = useSelector(getLocalCartSelector);
  const links = useAuthLinks();
  const isMobile = useIsMobile(); // Use the custom hook here

  const handleClickMenu = () => {
    setOpen(false);
  };

  const generateMenu = () => {
    const links = menuList.map((item, index) => (
      <Link
        key={index}
        to={`/products/categories/${item.title}`}
        className={styles.itemLink}
        onClick={handleClickMenu}
      >
        {item.name}
      </Link>
    ));
    setMenuItems(links);
  };

  useEffect(() => {
    if (menuList.length > 0) {
      generateMenu();
    }
  }, [menuList]);

  return (
    <nav
      className={`${styles.menuProducts} ${open ? styles.openProducts : ""}`}
    >
      {menuItems}
      {isMobile && ( // Render only for mobile devices
        <div className="d-flex">
          <NavList
            key={"burger"}
            items={links}
            wishlist={wishlist}
            cart={localCart}
            orderOnly={true}
            btnDisable={false}
            color={"#fff"}

          />
          {auth.isAuthenticated && (
            <div className="logo__exit" onClick={logout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  fill="#fff"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                />
                <path
                  fillRule="evenodd"
                  fill="#fff"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default MenuProducts;
