import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHttp } from '../../../../hooks/http.hook';
import { useMessageError, useMessageSuccess } from '../../../../hooks/message.hook';
import { useNavigate } from 'react-router-dom';
import CategoriesForm from '../../../../components/Forms/CategoriesForm/CategoriesForm'; // Assuming CategoriesForm is a separate component

const FormWrapper = ({ edit, create, id, close, discounts}) => {
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const navigator = useNavigate();
    const [initialValues, setInitialValues] = useState({
        name: "",
        title: "",
        description: "",
        active: false,
        discount: "",
        isMenu: false
    });

    const getById = async () => {
        try {
            const data = await request(`admin/categories/get/${id}`, "GET");
            setInitialValues(data.data);
            messageSuccess(data.message);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (edit) getById();
    }, [edit]);

    const onSubmit = async (values) => {
        try {
            if (create) {
                const data = await request(`admin/categories/create`, "POST", { ...values });
                messageSuccess(data.message);
                close(true);
            }

            if (edit) {
                const data = await request(`admin/categories/edit/${id}`, "PUT", { ...values });
                messageSuccess(data.message);
                close(true);
            }
        } catch (e) {
            messageError(e);
        }
    };

    return (
        <div>
            <CategoriesForm initialValues={initialValues} onSubmit={onSubmit} edit={edit} create={create} discounts={discounts}/>
        </div>
    );
};

export default FormWrapper;
