export const checkAuth = (auth) => {
    if (!auth.isAuthenticated) {
        return([
            { name: "Auth", url: "auth" },
            { name: "Orders", url: "orders" },
        ]);
    } else {
        if (auth.permissions) {
            const adminAccess = auth.permissions.admin?.access;
            const customerAccess = auth.permissions.customer?.access;

            if (adminAccess || customerAccess) {
                const adminUrls = [];
                const userUrls = [];
                const mainPath = adminAccess ? "admin/" : "user/";

                Object.entries(auth.permissions[adminAccess ? "admin" : "customer"].modules).forEach(([key, value]) => {
                    if (value.access) {
                        const name = key.charAt(0).toUpperCase() + key.slice(1);
                        const url = mainPath + key.toLowerCase();
                        (adminAccess ? adminUrls : userUrls).push({ name, url });
                    }
                });
                return(adminAccess ? adminUrls : userUrls);
            }
        }
    }
}