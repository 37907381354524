/* eslint-disable no-param-reassign */
import axios from 'axios';
const REACT_APP_API_URL = '/api/';
const $host = axios.create({
  baseURL: REACT_APP_API_URL + "openpath/",
});

const $authHost = axios.create({
  baseURL: REACT_APP_API_URL + "customer/",
});

const $adminHost = axios.create({
  baseURL: REACT_APP_API_URL + "admin/",
});
const $checkboxHost = axios.create({
  baseURL: REACT_APP_API_URL + "checkbox/",
});
const authInterceptor = config => {
  config.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('sessionData')).token}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
$adminHost.interceptors.request.use(authInterceptor);

export { $host, $authHost, $adminHost, $checkboxHost };
