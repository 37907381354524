import React from "react";

const CartIcon = ({ isAnimating, isCart, uniqueId }) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      key={uniqueId} // Унікальний ключ для кожної іконки
    >
      {/* Порожня корзина */}
      <path
        id={`basket-base-${uniqueId}`} // Унікальний id для кожного елемента
        d="M10 20 L54 20 L50 55 H14 L10 20"
        fill="none"
        strokeLinejoin="round"
      />
      <line
        id={`basket-handle-${uniqueId}`} // Унікальний id для кожного елемента
        x1="20"
        y1="10"
        x2="44"
        y2="10"
        strokeLinecap="round"
      />
  
      {/* Товари в корзині, які з'являються при заповненні */}
      {/* <circle
        cx="20"
        cy="15"
        r="4"
        fill="currentColor"
        opacity={isCart ? "1" : "0"}
      />
      <circle
        cx="32"
        cy="12"
        r="4"
        fill="currentColor"
        opacity={isCart ? "1" : "0"}
      />
      <circle
        cx="44"
        cy="15"
        r="4"
        fill="currentColor"
        opacity={isCart ? "1" : "0"}
      /> */}
  
      {/* Білий фон для галочки */}
      {/* {isCart && (
        <circle
          cx="50" // Позиція в правому верхньому куті
          cy="14"
          r="14"
          fill="white"
        />
      )}

      {/* Чорна галочка */}
      {/* <path
        id={`checkmark-${uniqueId}`} // Унікальний id для кожного елемента
        d="M47 12 L50 18 L55 10"
        fill="none"
        stroke="black" // Чорний колір для галочки
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30"
        strokeDashoffset="0"
        // strokeDashoffset={isAnimating ? "0" : "30"} // Лише анімовані при isAnimating
      /> */}
      {/* Білий фон для галочки в правому верхньому куті */}
      {isCart && <circle fill="white" cx="40" cy="17" r="15" />} 

      {/* Чорна галочка в правому верхньому куті */}
      {isCart && 
      <path
        className="path"
        fill="none"
        stroke="black" // Чорний колір для галочки
        strokeWidth="1.5"
        strokeDasharray="70.2"
        strokeDashoffset="70.2"
        d="M 49.6 9.6 L 36 23.2 L 30.4 17.6 L 27.6 20.4 L 36 28.8 L 52.4 12.4 z"
      >
         (
          <>
            <animate
              id="p1"
              attributeName="stroke-dashoffset"
              begin="0.2s"
              values="70.2;0"
              dur="1.5s"
              repeatCount="1"
              fill="freeze"
              calcMode="paced"
              restart="whenNotActive"
            />
            <animate
              id="f1"
              attributeName="fill"
              begin="p1.end"
              values="white; black" // Зміна кольору галочки
              dur="1.5s"
              fill="freeze"
              restart="whenNotActive"
            />
          </>
        )
      </path>}
      {/* Анімація */}
      {isCart && <style>
        {`
          #basket-base-${uniqueId} {
            stroke-dasharray: 100;
            stroke-dashoffset: 0;
            animation: draw 1s forwards;
          }
          #basket-handle-${uniqueId} {
            stroke-dasharray: 50;
            stroke-dashoffset: 0;
            animation: draw 1s forwards;
            animation-delay: 0.3s;
          }
          @keyframes draw {
            0% {
              stroke-dashoffset: 100;
            }
            100% {
              stroke-dashoffset: 0;
            }
          }
  
          /* Анімація появи товарів */
          #item1, #item2, #item3 {
            animation: ${isCart ? 'showItems' : 'none'} 0.5s forwards;
            animation-delay: 1s;
          }
          @keyframes showItems {
            from {
              opacity: 0;
              transform: translateY(-10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
  
          /* Анімація галочки */
          #checkmark-${uniqueId} {
            transition: stroke-dashoffset 0.5s ease;
          }
        `}
      </style>}
    </svg>
  );

  export default CartIcon;