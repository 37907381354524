import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { changeStatusOrders } from '../../../../http/ordersAPI';
import Icons from '../../../../components/Icons/Icons';
import styles from './Status.module.scss';
import { AuthContext } from '../../../../context/AuthContext';

const Status = ({ setspecifyOrderState, status, id }) => {
  const admin = useContext(AuthContext).permissions?.admin?.access;
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [statusChangeable, setStatusChangeable] = useState(status);
  
  // Define colors for each status
  const statusColors = {
    specified: 'blue',   // Уточнюються
    processed: 'green',  // В обробці
    send: 'purple',      // Відправлений
    completed: 'gray',   // Виконані
    abolition: 'red'     // Відмінені
  };

  // Status text representation
  const statusText = {
    specified: 'Уточнюються',
    processed: 'В обробці',
    send: 'Відправлений',
    completed: 'Виконані',
    abolition: 'Відмінені'
  };

  // Apply dynamic color based on the current status
  const color = statusColors[statusChangeable] || '#000'; // Default to black if no status match
  const statusElement = (
    <div style={{ color: color }}>
      {statusText[statusChangeable]}
    </div>
  );

  setspecifyOrderState(statusChangeable);

  const changeStatus = stat => {
    if (statusChangeable !== 'abolition') {
      changeStatusOrders(admin, id, { status: stat }).then(res => {
        setIsChangeStatus(false);
        setStatusChangeable(res.data.status);
      });
    }
  };

  const changeStatusElement = (
    <div>
      <select onChange={e => changeStatus(e.target.value)} defaultValue={statusChangeable} className={styles.select} disabled={statusChangeable === 'abolition'}>
        {Object.entries(statusText).map(([key, value]) => (
          <option key={key} value={key}>{value}</option>
        ))}
      </select>
    </div>
  );

  return (
    <div className={styles.status}>
      {isChangeStatus ? changeStatusElement : (
        <>
          <Icons
            className={styles.iconChange}
            type='edit'
            color={statusChangeable === 'abolition' ? '#ccc' : '#999'}
            filled
            width={20}
            height={20}
            onClick={() => { if (statusChangeable !== 'abolition') setIsChangeStatus(true); }}
          />
          <div className={styles.statusText}>{statusElement}</div>
        </>
      )}
    </div>
  );
};

Status.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
};

Status.defaultProps = {
  status: 'Невизначений',
};

export default Status;
