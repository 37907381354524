import { getAuth, getUser, getAdmin } from '../../http/userAPI';
import { saveWishListAction } from '../wishList/actions';
import { saveUserAction, saveUserIsAuthAction, saveUserIsLoadingAction, saveAllUserAction } from './actions';
import { saveloaderAction } from '../loader/actions';

export const checkAuthOperation = (admin) => dispatch => {
  dispatch(saveUserIsLoadingAction(true));
  dispatch(saveloaderAction(true))
  getAuth(admin)
    .then(res => {
      dispatch(saveUserAction(res.data.data));
      dispatch(saveUserIsAuthAction(true));
      dispatch(saveUserIsLoadingAction(false));
      dispatch(saveloaderAction(false));
    })
    .catch(err => {
      dispatch(saveloaderAction(false));
      dispatch(saveUserIsLoadingAction(false));
      return err;
    });
};

export const getUserOperation = () => dispatch => {
  dispatch(saveloaderAction(true))
  dispatch(saveUserIsLoadingAction(true));
  getUser().then(res => {
      if(res.data.status){
        dispatch(saveUserAction(res.data.data));
        dispatch(saveloaderAction(false));
      }
    })
    .catch(err => {
      dispatch(saveUserIsLoadingAction(false));
      dispatch(saveloaderAction(false));
      return err;
    });
}

export const getAdminOperation = () => dispatch => {
  dispatch(saveloaderAction(true))
  dispatch(saveUserIsLoadingAction(true));
  getAdmin().then(res => {
      if(res.data.status){
        dispatch(saveUserAction(res.data.data));
        dispatch(saveloaderAction(false));
      }
    })
    .catch(err => {
      dispatch(saveUserIsLoadingAction(false));
      dispatch(saveloaderAction(false));
      return err;
    });
}
// export const authorizOperation = value => dispatch => {
//   dispatch(saveUserIsLoadingAction(true));
//   loginCustomer(value)
//     .then(res => {
//       dispatch(
//         popupOpenOperation(`Привіт ${res.data.customer.firstName} ${res.data.customer.lastName}!`)
//       );
//       dispatch(saveUserAction(res.data.customer));
//       dispatch(saveUserIsAuthAction(true));
//       dispatch(saveModalAuthRegAction(false));
//     })
//     .catch(err => {
//       const message = Object.values(err.data).join('');
//       dispatch(popupOpenOperation(message, true));
//     });
//   dispatch(saveCustomerIsLoadingAction(false));
// };

// export const createCustomerOperation = value => dispatch => {
//   createCustomer(value)
//     .then(res => {
//       dispatch(popupOpenOperation(res.data.message));
//       dispatch(saveModalAuthRegAction(false));
//     })
//     .catch(err => {
//       const message = Object.values(err.data).join('');
//       dispatch(popupOpenOperation(message, true));
//     });
// };

export const outPutCustomerOperation = () => dispatch => {
  dispatch(saveUserAction({}));
  dispatch(saveWishListAction(null));
  dispatch(saveUserIsAuthAction(false));
  localStorage.setItem('token', '');
};