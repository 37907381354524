const bottlePrices = [
  { maxMl: 6, price: 30 },
  { maxMl: 10, price: 35 },
  { maxMl: 15, price: 40 },
  { maxMl: 20, price: 50 },
  { maxMl: 30, price: 60 },
  { maxMl: 50, price: 65 },
];

export const getBottleCountAndPrice = (ml) => {
  let remainingMl = ml;
  let totalCost = 0;
  let bottles = [];

  // Sort bottles by maxMl in descending order for convenience
  const sortedBottles = [...bottlePrices].sort((a, b) => a.maxMl - b.maxMl);
  const largestBottle = bottlePrices.find(bottle => bottle.maxMl === 50);
  const largeBottleCount = Math.floor(remainingMl / largestBottle.maxMl);
  if(largeBottleCount > 0) {
    bottles.push({ bottleSize: largestBottle.maxMl, count: largeBottleCount, price: largestBottle.price })
    totalCost += largeBottleCount * largestBottle.price;
  };
  let remainingMlAfterLargeBottles = remainingMl - largeBottleCount * largestBottle.maxMl;
  // Loop through each bottle size starting from the largest
  for (const bottle of sortedBottles) {
    // If the remaining ml is larger than or equal to the maxMl of the bottle, we add it
    if (remainingMlAfterLargeBottles <= bottle.maxMl) {
      const bottleCount = remainingMlAfterLargeBottles / bottle.maxMl;
      
      bottles.push({ bottleSize: bottle.maxMl, count: bottleCount, price: bottle.price });
      totalCost += 1 * bottle.price;
      remainingMlAfterLargeBottles -= bottleCount * bottle.maxMl;
      return { bottles, totalCost };
    }
    
  }

  // // If there is any ml left that is not covered, add the smallest bottle
  // if (remainingMl > 0) {
  //   const smallestBottle = sortedBottles[sortedBottles.length - 1];
  //   bottles.push({ bottleSize: smallestBottle.maxMl, count: 1 });
  //   totalCost += smallestBottle.price;
  // }

  
};