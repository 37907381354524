import React from 'react';
import styles from './CarouselSettings.module.scss';
import axios from 'axios';

const CarouselItemsList = ({ caruselItems, fetchCaruselItems, selectedCaruselId, config }) => {
  const handleDeleteSlide = async (slideId) => {
    try {
      await axios.delete(`/api/admin/carusels/delete-slide?caruselId=${selectedCaruselId}&slideId=${slideId}`, config);
      fetchCaruselItems();
    } catch {
      alert('Помилка при видаленні слайду');
    }
  };

  const handleUpdateSlideOrder = async (slideId, newOrder) => {
    try {
      await axios.put(
        '/api/admin/carusels/update-slide-order',
        { caruselId: selectedCaruselId, slideId, newOrder },
        config
      );
      fetchCaruselItems();
    } catch {
      alert('Помилка при оновленні порядку слайду');
    }
  };

  const handleDeleteAllSlides = async () => {
    try {
      await axios.delete('/api/admin/carusels/delete-all-slides', {
        data: { caruselId: selectedCaruselId },
        ...config,
      });
      fetchCaruselItems();
    } catch {
      alert('Помилка при видаленні всіх слайдів');
    }
  };

  return (
    <div className={styles.carouselItems}>
      <h2>Елементи каруселі</h2>
      {caruselItems.length > 0 ? (
        <ul>
          {caruselItems.map((item) => (
            <li key={item.slideId} className={styles.item}>
              <span>
                {item.title || item.name} - Порядок: {item.order}
              </span>
              <input
                type="number"
                value={item.order}
                onChange={(e) => handleUpdateSlideOrder(item.slideId, e.target.value)}
                className={styles.input}
              />
              <button onClick={() => handleDeleteSlide(item.slideId)} className={styles.button}  style={{ backgroundColor: "red" }}>Видалити</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>Немає елементів у каруселі</p>
      )}
      {/* <button onClick={handleDeleteAllSlides} className={styles.button}>
        Видалити всі слайди
      </button> */}
    </div>
  );
};

export default CarouselItemsList;
