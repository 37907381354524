import React from 'react';
import { useSelector } from 'react-redux';
import { getLocalCartSelector } from '../../../store/cart/selectors';
import { productsLoadingSelector } from '../../../store/products/selectors';
import CartItem from '../CartItem/CartItem';
import styles from './CartList.module.scss';

const CartList = ({disableDel = false}) => {
  const localCart = useSelector(getLocalCartSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  let cartList = null;

  // Якщо продукти завантажуються, повертаємо повідомлення
  // if (productsLoading) {
  //   return <li style={{ textAlign: 'center', padding: '50px 0' }} className={styles.title}>Завантаження товарів...</li>;
  // }

  // Перевірка наявності товарів в кошику, якщо користувач авторизований

  if (localCart.length) {
    cartList = localCart.map(p => (
      <CartItem key={p.product._id} product={p.product} cartQuantity={p.cartQuantity} cart={localCart} disableDel={disableDel} bottleCost={p.bottleCost}/>
    ));
  } 
  // Виведення повідомлення, якщо кошик порожній
  else {
    cartList = (
      <li key="empty-cart" style={{ textAlign: 'center', padding: '50px 0' }} className={styles.title}>
        В кошику немає товарів
      </li>
    );
  }

  return <ul className={styles.list}>{cartList}</ul>;
};

export default CartList;
