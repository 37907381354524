import React, { useEffect, useState } from "react";
import styles from "./Products.module.scss";
import { Table, Modal, Button } from 'react-bootstrap';
import { useHttp } from "../../../hooks/http.hook";
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import { getAdminProductsOperation } from "../../../store/products/operations";
import { getAdminProductsSelector } from "../../../store/products/selectors";
import Product from "./Product/Product";
import Container from "../../../components/Container/Container";
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";
import ProductsGrid from "./ProductsGrid";

const Products = () => {
    const dispatch = useDispatch();
    // Из за того, что используется редакс, не правильно отображает данные в пагинации, нужно посмотреть как исправить
    // const products = useSelector(getAdminProductsSelector)
    const [products, setProducts] = useState([]);
    const [fiteredProducts, setFileredProducts] = useState([])
    const { request } = useHttp();
    const messageError = useMessageError();
    const messageSuccess = useMessageSuccess();
    const [showModal, setShowModal] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [delId, setDelId] = useState();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const perPage = 50;
    const [refreshOrders, setRefreshOrders] = useState(true);
    const [activeTab, setActiveTab] = useState('all');
    const [searchInput, setSearchInput] = useState('');
    const [productsCount, setProductsCount] = useState({
        all: 0,
        active: 0,
        inactive: 0
    })
    let ordersFilter = {};
    const filteredProducts = products.filter((product) => {
        if (activeTab === 'active') return product.enabled;
        if (activeTab === 'inactive') return !product.enabled;
        return true;
    });

    // const startIndex = (startPage - 1) * perPage;
    // const endIndex = startIndex + perPage;
    // let productsToDisplay = filteredProducts.slice(startIndex, endIndex);
    if (searchInput === '') {
        ordersFilter = { perPage, startPage };
    } else {
        ordersFilter = { perPage, startPage, find: searchInput };
    }

    const search = (event) => {
        setSearchInput(event);
    }

    const fetchProductsData = async () => {
        try {
            const reqCount = await request('admin/products/get/count', "GET");
            setProductsCount({
                all: reqCount.data.all,
                active: reqCount.data.active,
                inactive: reqCount.data.inactive
            });
            const reqProducts = await request('admin/products/getall', "GET");
            setProducts(reqProducts)
            setFileredProducts(reqProducts)
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const data = await request('admin/categories/getall', 'GET');
            setCategories(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    const fetchBrands = async () => {
        try {
            const data = await request('admin/brands/getall', 'GET');
            setBrands(data.data);
        } catch (error) {
            messageError(error);
        }
    };

    const handleDeleteAccess = async () => {
        setShowDel(false);
        try {
            const data = await request(`admin/products/delete/${delId}`, 'DELETE');
            messageSuccess(data.message);
            setDelId("");
            dispatch(getAdminProductsOperation(ordersFilter));
        } catch (error) {
            messageError(error);
        }
    };
    const serchHandleer = () => {
        if (searchInput) {
            const findItems = products.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()));
            setFileredProducts(findItems);
        } else {
            setFileredProducts(products);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            serchHandleer();
        }
    };

    useEffect(() => {
        // dispatch(getAdminProductsOperation(ordersFilter));

        setRefreshOrders(false);
    }, [refreshOrders, startPage]); // Оновлення продуктів лише при монтуванні компоненту


    useEffect(() => {
        fetchCategories();
        fetchBrands();
        fetchProductsData();
    }, []);

    useEffect(() => {
        if (activeTab === "all") {
            setFileredProducts(products);
        } else if (activeTab === "active") {
            setFileredProducts(products.filter(product => product.enabled));
        } else if (activeTab === "inactive") {
            setFileredProducts(products.filter(product => !product.enabled));
        }
    }, [activeTab, products]);
    // useEffect(() => {
    //     if (ordersFilter.find) {
    //         serchHandleer()
    //     } else {
    //         fetchProductsData()
    //     }
    // }, [searchInput])

    const handleDelete = async (id) => {
        setShowDel(true);
        setDelId(id);
        fetchProductsData();
    };

    useEffect(() => {
        const totalPages = Math.ceil(filteredProducts.length / perPage);

        if (startPage > totalPages && totalPages > 0) {
            setStartPage(1);
        }
    }, [filteredProducts.length, startPage, perPage]);

    const handleActive = async (id) => {
        try {
            const data = await request(`admin/products/active/${id}`, 'PUT');
            messageSuccess(data.message);
            dispatch(getAdminProductsOperation(ordersFilter));
            fetchProductsData()
        } catch (error) {
            messageError(error);
        }
    };

    const handleCreate = () => {
        setShowModal(true);
        setSelectedProduct(null);
    };
    const handleEdit = (id) => {
        setSelectedProduct(id);
        setShowModal(true);
        fetchProductsData()
    };
    const handleCloseModal = async (success) => {
        setShowModal(false);
        if (success) {
            dispatch(getAdminProductsOperation());
        }
    };
    const handleCloseDel = () => {
        setShowDel(false);
        setDelId("");
    }
    return (
        <>
            <section>
                <BurgerMenu />
                <Container>
                    <h1>Всі Продукти</h1>
                    {/* <p>Kількість позицій: <span>{products.length}</span></p> */}
                    <div className="d-flex justify-content-between mb-5">
                        <div className={styles.filter}>
                            <div className={styles.filterWrapper}>
                                <div>
                                    <input className="form-control" onChange={(e) => setSearchInput(e.target.value.trim())}
                                        onKeyDown={handleKeyDown} name='search' type='text' placeholder="Пошук" tabIndex='0' />
                                </div>
                                <Button variant="btn btn-outline-secondary" onClick={() => serchHandleer()}>Пошук</Button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button variant="dark" onClick={() => handleCreate()}>Створити</Button>{' '}
                        </div>
                    </div>

                    <div>
                        <h1>Товари</h1>
                        <ul className="nav nav-tabs mb-4">
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('all')}
                                    href="#"
                                >
                                    Все товары ({productsCount.all})
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'active' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('active')}
                                    href="#"
                                >
                                    Активные товары ({productsCount.active})
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'inactive' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('inactive')}
                                    href="#"
                                >
                                    Неактивные товары ({productsCount.inactive})
                                </a>
                            </li>
                        </ul>
                        <ProductsGrid
                            products={fiteredProducts.slice((startPage - 1) * perPage, startPage * perPage)}
                            handleActive={handleActive}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    </div>
                    <Pagination perPage={perPage} startPage={startPage} setPage={setStartPage} productsQuantity={fiteredProducts.length} />

                    {/* Modal for editing category */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedProduct ? "Редагування" : "Створення"} Продукту</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedProduct && <Product categories={categories} brands={brands} create={false} edit={true} id={selectedProduct} onSubmit={handleCloseModal} close={handleCloseModal} />}
                            {!selectedProduct && <Product categories={categories} brands={brands} create={true} edit={false} id={selectedProduct} onSubmit={handleCloseModal} close={handleCloseModal} />}
                        </Modal.Body>
                    </Modal>
                    <Modal show={showDel} onHide={handleCloseDel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Видалити</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Button variant="danger" onClick={() => handleDeleteAccess()}>Видалити</Button>
                        </Modal.Body>
                    </Modal>
                </Container>
            </section>
        </>
    )
}

export default Products;