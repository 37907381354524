import { saveloaderAction } from '../loader/actions';
import { saveModalOrderAction, saveModalPopupAction } from './actions';

export const orderOpenOperation = (message, failed, action) => dispatch => {
  const values = {
    isOpen: true,
    message,
    failed,
    action: () => {
      return null;
    },
  };

  if (action) {
    values.action = action;
  }

  dispatch(saveModalOrderAction(values));
  dispatch(saveloaderAction(false));
};

export const popupOpenOperation = (message, failed, action) => dispatch => {
  const values = {
    isOpen: true,
    message,
    failed,
    action: () => {
      return null;
    },
  };

  if (action) {
    values.action = action;
  }

  dispatch(saveModalPopupAction(values));
};