import React, { useState } from "react";
import './Registration/Registration.scss';
import SignIn from "./SignIn/SignIn";
import Registration from "./Registration/Registration";
import styles from "./Auth.module.scss";

const Auth = () =>  {
    const[auth, setAuth] = useState({signin:true, registration:false});
    const clickHandler = (name) => {
        setAuth({ ...auth, signin: false, registration: false });
        setAuth((prevState) => ({ ...prevState, [name]: true }));
    }
    
    return (
        <>
            <section className="registration__form-container-wrapper">

                <div className="registration__form-container">
                    <div className="d-flex gap-2">                    
                        <h2 className={styles.name__link} onClick={()=>clickHandler("signin")}>Увійти</h2>
                        <h2 className={styles.name__link} onClick={()=>clickHandler("registration")}>Реєстрація</h2>
                    </div>

                    {auth.signin && <SignIn /> }
                    {auth.registration && <Registration />}
                </div>
            </section>
        </>
    );
}

export default Auth;