import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import styles from "./BlogCart.module.scss";
import Icons from "../../Icons/Icons";
import moment from "moment";
import "moment/locale/uk";

const BlogCart = ({blogOne}) =>  {
    const [blogCart, setBlogCart] = useState(blogOne);
    // Parse the date string into a Date object
    const date = new Date(blogCart.releaseDate);

    // Format the date using moment.js with month name and time zone
    const formattedDate = moment(date).locale("uk").format(" D MMM, YYYY HH:mm:ss z");
    
    return(
    <>
        <div className="col-md-4">
            <div className={styles.cart}>
                <div className={styles.cart__imageWrapper}>
                    <picture>
                        <img className={styles.cart__image} src={blogCart.imageUrl} alt="cart image" />
                    </picture>
                </div>
                <div className={styles.cart__title}>
                    <a href={"/blog/" + blogCart._id}>{blogCart.title}</a>
                </div>
                <div className={`${styles.cart__mark}`}>
                    <Icons type="clock" color="#727272"/>
                    <time>{formattedDate}</time>
                </div>
            </div>
        </div>
    </>)
}

export default BlogCart;