import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from "./MySearch.module.scss";
import { useDispatch } from 'react-redux';
import { saveloaderAction } from '../../../store/loader/actions';
import { productsLoadingAction, saveProductsAction } from '../../../store/products/actions';
import { searchProducts } from '../../../http/productAPI';
import { useNavigate } from 'react-router-dom';
import { useMessageError } from '../../../hooks/message.hook';

const MySearch = () => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState('');
    const [noProducts, setNoProducts] = useState(false);  // Додаємо стан для товарів
    const navigate = useNavigate();
    const messageError = useMessageError();
    const search = () => {
        dispatch(saveloaderAction(true));
        dispatch(productsLoadingAction(true));
        searchProducts(searchInput).then(res => {
            setSearchInput('');
            if (res.data.length === 0) {
                // Якщо товари не знайдено, показуємо повідомлення
                setNoProducts(true);
                {
                    noProducts && (
                        messageError("Товарів з такою назвою немає")
                    )
                }
            } else {
                // Якщо товари знайдено, зберігаємо їх і переходимо на сторінку товарів
                setNoProducts(false);
                dispatch(saveProductsAction(res.data));
                navigate("/products");
            }
            dispatch(productsLoadingAction(false));
            dispatch(saveloaderAction(false));
        }).catch(err => {
            dispatch(productsLoadingAction(false));
            dispatch(saveloaderAction(false));
            return err;
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            search();
        }
    };

    return (
        <div className={styles.search__container}>
            <input
                type="text"
                className={styles.search__input}
                placeholder="Пошук"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <button className={styles.search__button} onClick={() => search()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" className={styles.search_loop} height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
            </button>
        </div>
    );
};

export default MySearch;
