import React from 'react';
import './RequiredIndicator.scss';

const RequiredIndicator = () => {
    return (
        <span className="required-indicator">
            *
            <span className="tooltip-text">Обов'язкове поле</span>
        </span>
    );
};

export default RequiredIndicator;

