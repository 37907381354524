import React from "react";
import AppRoutes from "../../routes/AppRoutes";
import './Main.scss';
import ContainerPage from "../ContainerPage/ContainerPage";
import { getloaderSelector} from '../../store/loader/selectors';
import { ToastContainer } from "react-toastify";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import UpArrow from "../ArrowUp/UpArrow";

const Main = () => {
    const routes = AppRoutes();
    const loader = useSelector(getloaderSelector);
    return (
        <>
          <ContainerPage>           
            <main className="main_wrapper">
                {routes}
                <UpArrow />
            </main> 
            {loader && <Loader/>}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />   
          </ContainerPage>  
        </>
    );
};

export default Main;