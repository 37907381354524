import { getCartOperation, setCartTotalPriceOperation } from './cart/operations';
import { getAdminProductsOperation, getProductsOperation } from './products/operations';
import { wishListLoadingAction } from './wishList/actions';
import { getWishListOperation, updateWishListOperation } from './wishList/operations';

// Utility to safely parse localStorage
const parseLocalStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || null;
  } catch (error) {
    console.error(`Failed to parse ${key} from localStorage:`, error);
    return null;
  }
};

export const getDataOperation = (isAuth, admin) => (dispatch) => {
  const storageWishList = { products: parseLocalStorageItem('wishList') || [] };
  const localCart = parseLocalStorageItem('cart');

  // Fetch products based on admin status
  if (admin) {
    // dispatch(getAdminProductsOperation());
  } else {
    dispatch(getProductsOperation());
  }

  // Handle wishlist actions
  dispatch(wishListLoadingAction(true));

  if (isAuth) {
    dispatch(getWishListOperation(admin));
    dispatch(updateWishListOperation(admin, storageWishList));
    dispatch(getCartOperation(admin));
  } else {
    dispatch(setCartTotalPriceOperation(localCart));
    dispatch(getCartOperation());
  }
};
