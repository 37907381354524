import { useNavigate } from "react-router-dom";
import { useAuth } from "./isAuth";

export const useLogout = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    return () => {
        auth.logout();
        navigate("/index");
    };
};
