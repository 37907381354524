import React from 'react';
import { Field } from 'formik';
import styles from './PaymentDataInputs.module.scss';

const PaymentDataInputs = () => {
  return (
    <div className={styles.container}>
      {/* MonoPay Button */}
      <label htmlFor="paymentMonoPay" className={styles.monoPayButton}>
        <Field type="radio" name="paymentMethod" value="MonoPay" id="paymentMonoPay" />
        <div className={styles.monoPay}>
          <span className={styles.mono}>mono</span><span className={styles.pay}>Pay</span>
        </div>
      </label>

      {/* Google Pay Button */}
      {/* <label htmlFor="paymentGooglePay" className={styles.googlePayButton}>
        <Field type="radio" name="paymentMethod" value="GooglePay" id="paymentGooglePay" />
        <div className={styles.googlePay}>
          <span className={styles.googleIcon}>G</span><span className={styles.pay}>Pay</span>
        </div>
      </label> */}

      {/* Card Error Message */}
      {/* <div className={styles.cardError}>
        <span className={styles.cardErrorIcon}>💳</span>
        <span className={styles.cardErrorMessage}>Enter card details</span>
      </div> */}
    </div>
  );
};

export default PaymentDataInputs;
