import React from "react";
import RegistrationForm from "../../../components/Auth/RegistrationForm/RegistrationForm";
import './Registration.scss';

const Registration = () =>  {
    return (
        <>
            <RegistrationForm/>
        </>
    );
}

export default Registration;