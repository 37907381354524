import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
// import Loader from '../Loader/Loader';
import styles from './ProductSizes.module.scss';

const ProductSizes = ({id, products, isCard }) => {

  const [productsCard, setProductsCard] = useState([]);

  // if (colorsLoading || productColorsLoading) {
  //   return <Loader />;
  // }
  const productsCards = () => {
    const productsList = products.map(product => {
      return (
        <li
          key={product.itemNo}
          className={id === product._id ? `${styles.item} ${styles.itemActive}` : styles.item}
        >
          <Link to={`../../products/item/${product._id}`} target="true">
            <div  className={styles.border}>
              <div  className={styles.color}>
                {product.sizes}
              </div>
            </div>
          </Link>
        </li>
      );
    });
    setProductsCard(productsList)
  }
useEffect(() => {
  productsCards();
}, [])
  // if (isCard) {
    return <ul className={styles.colors}>{productsCard}</ul>;
  // }

  // return (
  //   <div className={styles.container}>
  //     <div className={styles.text}>
  //       Цвет корпуса: <span className={styles.textColor}>{color}</span>
  //     </div>
  //     <ul className={styles.colors}>{colorList}</ul>
  //   </div>
  // );
};

// ProductColors.propTypes = {
//   color: PropTypes.string.isRequired,
//   descForColor: PropTypes.string,
//   isCard: PropTypes.bool,
// };

// ProductColors.defaultProps = {
//   descForColor: '',
//   isCard: false,
// };

export default ProductSizes;
