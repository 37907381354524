import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './CarouselSettings.module.scss';
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import { AuthContext } from '../../../context/AuthContext';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import CreateCarouselForm from './CreateCarouselForm';
import UpdateCarouselForm from './UpdateCarouselForm';
import CarouselList from './CarouselList';
import CarouselItemsList from './CarouselItemsList';
import AddSlideForm from './AddSlideForm';

const CarouselSettings = () => {
  const [caruselItems, setCaruselItems] = useState([]);
  const [carusels, setCarusels] = useState([]);
  const [selectedCaruselId, setSelectedCaruselId] = useState('');
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const messageError = useMessageError();
  const messageSuccess = useMessageSuccess();
  const auth = useContext(AuthContext);

  const config = {
    headers: auth.isAuthenticated ? { Authorization: `Bearer ${auth.token}` } : {},
  };

  useEffect(() => {
    getCarusels();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (selectedCaruselId) {
      fetchCaruselItems(selectedCaruselId);
    }
    getProductByCarusel();
    getBlogByCarusel();
  }, [selectedCaruselId]);

  const getCarusels = async () => {
    try {
      const response = await axios.get('/api/admin/carusels/get/all', config);
      setCarusels(response.data.data);
      messageSuccess('Каруселі успішно завантажено');
    } catch {
      messageError('Помилка при завантаженні каруселей');
    }
  };

  const fetchCaruselItems = async (caruselId) => {
    try {
      const response = await axios.get(`/api/admin/carusels/${caruselId}/items`, config);
      setCaruselItems(response.data.data);
    } catch {
      messageError('Помилка при завантаженні слайдів');
    }
  };

  const getProductByCarusel = async () => {
    try {
      const response = await axios.get('/api/admin/products/productsforcaruser', config);
      setProducts(response.data);
      messageSuccess('Продукти успішно завантажено');
    } catch {
      messageError('Помилка при завантаженні продуктів');
    }
  };

  const getBlogByCarusel = async () => {
    try {
      const response = await axios.get('/api/admin/blog/blogforcaruser', config);
      setBlogs(response.data.data);
      messageSuccess('Блоги успішно завантажено');
    } catch {
      messageError('Помилка при завантаженні блогів');
    }
  };

  return (
    <div className={styles.carouselSettings}>
      <BurgerMenu />
      <h1 className={styles.heading}>Налаштування каруселі</h1>

      <CreateCarouselForm onSuccess={getCarusels} config={config} />
      <UpdateCarouselForm
        carusels={carusels}
        selectedCaruselId={selectedCaruselId}
        setSelectedCaruselId={setSelectedCaruselId}
        onSuccess={getCarusels}
        config={config}
      />
      <CarouselList
        carusels={carusels}
        selectedCaruselId={selectedCaruselId}
        setSelectedCaruselId={setSelectedCaruselId}
      />
      <CarouselItemsList
        caruselItems={caruselItems}
        fetchCaruselItems={() => fetchCaruselItems(selectedCaruselId)}
        selectedCaruselId={selectedCaruselId}
        config={config}
      />
      <AddSlideForm
        selectedCaruselId={selectedCaruselId}
        products={products}
        blogs={blogs}
        fetchCaruselItems={() => fetchCaruselItems(selectedCaruselId)}
        config={config}
      />
    </div>
  );
};

export default CarouselSettings;
