import React from "react";
import logo from '../../theme/logo/jado light +slogan.png';

const AboutUs = () => {
  return (
    <section style={styles.container}>
      <header style={styles.header}>
        <img src={logo} alt="JADO Logo" style={styles.logo} />
        <h1 style={styles.title}>Про нас</h1>
      </header>
      <article>
        <p style={styles.text}>
          Ласкаво просимо до <strong>JADO</strong> — місця, де аромати стають мистецтвом створення
          власного стилю!
        </p>
        <p style={styles.text}>
          Ми спеціалізуємося на продажі оригінальної парфумерії як у повних флаконах, так і на
          розпив. Розпив — наша ключова послуга, яка дозволяє спробувати аромати різних брендів без
          необхідності купувати повнорозмірний флакон. Придбати будь-який аромат можна від 1 мл.
        </p>
        <p style={styles.text}>
          У нашому асортименті тисячі ароматів на будь-який смак і бюджет — від люксових до
          найвишуканіших нішевих парфумів, створених найвідомішими парфумерами світу.
        </p>
        <p style={styles.text}>
          Ми завжди орієнтовані на те, щоб пропонувати найактуальніші парфуми з усього світу. Це
          дозволяє нашим клієнтам бути в тренді та відкривати для себе справжні парфумерні шедеври.
        </p>
        <p style={styles.text}>
          Наш офлайн-магазин у Кременчуці Полтавської області працює вже понад 15 років, заслуживши
          довіру та любов покупців. Тепер ми створили цей сайт, щоб зробити наші аромати доступними
          для всіх куточків України.
        </p>
        <p style={styles.text}>
          Знайдіть свій ідеальний аромат разом із <strong>JADO</strong> — і нехай кожен ваш день буде
          сповнений краси та натхнення!
        </p>
      </article>
    </section>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.6",
    textAlign: "justify",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  logo: {
    maxWidth: "150px",
    height: "auto",
  },
  title: {
    fontSize: "28px",
    marginBottom: "20px",
    color: "#333",
  },
  text: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "15px",
  },
};

export default AboutUs;
