import React from 'react';
import MyTextInput from '../../../components/MyForms/MyTextInput/MyTextInput';

const CustomerDataInputs = ({formik}) => {
  return (
    <div>
      <h3 className='checkout__title'>Дані замовника</h3>
      <MyTextInput label="Ім'я" name='firstName' type='text' placeholder="Введіть ім'я" tabIndex='0' />
      <MyTextInput label='Прізвище' name='lastName' type='text' placeholder='Введіть прізвище' tabIndex='0' />
      <MyTextInput label='Телефон' name='phone' type='tel' placeholder='Введіть номер телефона' tabIndex='0' />
      <MyTextInput label='Email' name='email' type='email' placeholder='Введіть email' tabIndex='0' />
      <hr />
    </div>
  );
};

export default CustomerDataInputs;
