import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';

const DiscountForm = ({ initialValues, onSubmit, edit , create}) => {
    
    const formik = useFormik({
        initialValues:initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Обов'язкове поле"),
            quantity: Yup.string().required("Обов'язкове поле"),
            active: Yup.boolean().required("Обов'язкове поле"),
            dateEnd: Yup.string().required("Обов'язкове поле"),
        }),
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            resetForm();
        },
    });
    useEffect(() => {
        formik.setValues(initialValues);
    }, [initialValues]);

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Назва <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="quantity">
                    <Form.Label>Відсоток знижки <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Введіть відсоток знижки"
                        name="quantity"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.quantity}
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                        <div className="text-danger">{formik.errors.quantity}</div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="dateEnd">
                    <Form.Label>Дата завершення <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Enter dateEnd"
                        name="dateEnd"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dateEnd}
                    />
                    {formik.touched.dateEnd && formik.errors.dateEnd ? (
                        <div className="text-danger">{formik.errors.dateEnd}</div>
                    ) : null}
                  </Form.Group>
                <Form.Group controlId="active">
                    <Form.Label>Active <RequiredIndicator /></Form.Label>
                    <Form.Check
                        type="switch"
                        id="active-checkbox"
                        name="active"
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                    />
                    {formik.touched.active && formik.errors.active ? (
                        <div className="text-danger">{formik.errors.active}</div>
                    ) : null}
                </Form.Group>
                <Button variant="dark" type="submit">
                    {create && "Створити"} 
                    {edit && "Редагувати"}
                </Button>
            </Form>
        </div>
    );
};

export default DiscountForm;
