import React, { useEffect, useState } from "react";
import styles from "./Menu.module.scss";
import DoubleSlider from "../../DoubleSlider/DoubleSlider";

const Menu = ({ open, filterProducts, filter, selected, ordersFilter }) => {
  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (Object.keys(filterProducts).length > 0) {
      const sizesOpt = filterProducts.filters.sizes.map((item, index) => (
        <option key={index} value={item}>
          {item} мл
        </option>
      ));
      setSizes(sizesOpt);

      const brandsOpt = filterProducts.filters.brand.map((brandArray, index) => (
        <option key={brandArray[0]._id} value={brandArray[0]._id}>
          {brandArray[0].name}
        </option>
      ));
      setBrands(brandsOpt);
    }
  }, [filterProducts]);

  return (
    <nav className={`${styles.menu} ${open ? `${styles.open}` : ''}`}>
      <div className={`${styles.menu__wrapper}`}>
        <div className={styles.select__wrapper}>
          <select
            name="sizes"
            id="sizes"
            value={ordersFilter?.filter?.sizes || 0}
            onChange={(e) => {
              filter("sizes", e.target.value);
            }}
          >
            <option value={0}>Об'єм</option>
            {sizes}
          </select>
        </div>
        <div className={styles.select__wrapper}>
          <select
            name="brands"
            id="brands"
            value={ordersFilter?.filter?.brand || ""}
            onChange={(e) => {
              filter("brand", e.target.value);
            }}
          >
            <option value="">Бренд</option>
            {brands}
          </select>
        </div>
        <DoubleSlider filterProducts={filterProducts} filter={filter} ordersFilter={ordersFilter} />
      </div>
    </nav>
  );
};

export default Menu;
