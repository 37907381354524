import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import parser from "html-react-parser";
import { getOne } from "../../../http/blogAPI";
import { useParams } from 'react-router';
import Container from "../../../components/Container/Container";
import styles from "./BlogPagetoOne.module.scss";
import Icons from "../../../components/Icons/Icons";
import moment from "moment";
import "moment/locale/uk";

const BlogPagetoOne = () =>  {
    const [blog, setBlog] = useState();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(true);

    const getBlog = async() =>{
        try {
            const blog = await getOne(params.id);
            if (blog.data.status) {
                setBlog(blog.data.data);
                setDate(blog.data.data.releaseDate)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    } 
    useEffect(() => {
        getBlog();
    }, [])
    const formattedDate = moment(date).locale("uk").format(" D MMM, YYYY HH:mm:ss z");

    if (loading) {
        return <Loader />;
    }
    return(
    <>
        <Container>
                <div className={styles.cart__img}>
                    <picture>
                        <img src={blog.imageUrl} alt="cart image" />
                    </picture>
                </div>
                <div className={styles.cart__title}>
                    <a href={"/blog/" + blog._id}>{blog.title}</a>
                </div>
                <div className={`${styles.cart__mark}`}>
                    <Icons type="clock" color="#727272"/>
                    <time>{formattedDate}</time>
                </div>
                <div className={`${styles.content}`}>
                {parser(blog.content)}
                </div>
        </Container>
    </>)
}

export default BlogPagetoOne;