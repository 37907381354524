import { SET_MODAL_ORDER, SET_MODAL_POPUP, SET_MODAL_AUTH_REG } from './types';

export const saveModalOrderAction = bool => {
  return { type: SET_MODAL_ORDER, payload: bool };
};
// export const saveModalCartAction = bool => {
//   return { type: SET_MODAL_CART, payload: bool };
// };
export const saveModalAuthRegAction = bool => {
  return { type: SET_MODAL_AUTH_REG, payload: bool };
};

export const saveModalPopupAction = bool => {
  return { type: SET_MODAL_POPUP, payload: bool };
};
