import {
  SET_ORDERS,
  SET_ALL_ORDERS,
  SET_ORDERS_LOADING
} from './types';

const initialState = {
  order: {},
  allOrders: [],
  orderLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS: {
      return { ...state, order: action.payload };
    }
    case SET_ALL_ORDERS: {
      return { ...state, allOrders: action.payload };
    }
    case SET_ORDERS_LOADING: {
      return { ...state, orderLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
