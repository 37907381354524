import {$host, $authHost, $adminHost } from './index';

// @route   POST /orders
// @desc    Place Order
// @access  Private
export const placeOrder = async (admin, values) => {
  console.log("🚀 ~ placeOrder ~ values:", values)
  if(admin){
    const res = await $adminHost.post('orders', values).catch(err => {
      throw err.response;
    });
    return res; 
  }
  if(!admin){
    const res = await $authHost.post('orders', values).catch(err => {
      throw err.response;
    });
    return res; 
  }
};
export const placeOrderNoAuth = async ( values) => {
    console.log("🚀 ~ placeOrderNoAuth ~ values:", values)
    const res = await $host.post('orders', values).catch(err => {
      throw err.response;
    });
    return res; 
};
// @route   PUT /orders/:id
// @desc    Update order
// @access  Private
export const updateOrder = async (admin, id, value) => {
  if(admin){
    const res = await $adminHost.put(`orders/${id}`, value).catch(err => {
      throw err.response;
    });
    return res;
  }
  if(!admin){
    const res = await $authHost.put(`orders/${id}`, value).catch(err => {
      throw err.response;
    });
    return res;
  }
};

// @route   PUT /orders/cancel/:id
// @desc    Cancel order
// @access  Private
export const cancelOrder = async (admin, id) => {
  if(admin){
    const res = await $adminHost.put(`orders/cancel/${id}`).catch(err => {
      throw err.response;
    });
    return res; 
  }
  if(!admin){
    const res = await $authHost.put(`orders/cancel/${id}`).catch(err => {
      throw err.response;
    });
    return res; 
  }
};

// @route   PUT /orders/status/:id
// @desc    Cancel order
// @access  Private
export const changeStatusOrders = async (admin, id, value) => {
  if(admin){
    const res = await $adminHost.put(`orders/status/${id}`, value).catch(err => {
      throw err.response;
    });
    return res; 
  }
  // if(!admin){
  //   const res = await $authHost.put(`orders/status/${id}`, value).catch(err => {
  //     throw err.response;
  //   });
  //   return res; 
  // }
};
export const abolitionOrders = async (admin, id, value) => {
  if(admin){
    const res = await $adminHost.put(`orders/abolition/${id}`, value).catch(err => {
      throw err.response;
    });
    return res; 
  }
};
// @route   DELETE /orders/:id
// @desc    Delete order
// @access  Private
export const deleteOrder = async (admin, id) => {
  if(admin){
    const res = await $adminHost.delete(`orders/${id}`).catch(err => {
      throw err.response;
    });
    return res;
  }
  // if(!admin){
  //   const res = await $authHost.delete(`orders/${id}`).catch(err => {
  //     throw err.response;
  //   });
  //   return res;
  // }
};

// @route   GET /orders
// @desc    Get all orders
// @access  Private
export const getCustomerOrders = async (admin) => {
  if(admin){
    const res = await $adminHost.get('orders').catch(err => {
      throw err.response;
    });
    return res; 
  }
  if(!admin){
    const res = await $authHost.get('orders').catch(err => {
      throw err.response;
    });
    return res; 
  }
};

// @route   GET /orders
// @desc    Get all orders
// @access  Private
export const getAllOrders = async (admin, values, status) => {
  const paginatonStr = new URLSearchParams(values).toString();
  if(admin){
    const res = await $adminHost.get(`orders/all?${paginatonStr}`, status).catch(err => {
      throw err.response;
    });
    return res;
  }
  if(!admin){
    const res = await $authHost.get(`orders/all?${paginatonStr}`, status).catch(err => {
      throw err.response;
    });
    return res;
  }
};

// @route   GET /orders/:id
// @desc    Get one order by orderNo
// @access  Private
export const getOrderById = async (admin, id) => {
  if(admin){
    const res = await $adminHost.get(`orders/${id}`).catch(err => {
      throw err.response;
    });
    return res; 
  }
  if(!admin){
    const res = await $authHost.get(`orders/${id}`).catch(err => {
      throw err.response;
    });
    return res; 
  }
};
