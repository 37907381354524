import {
  SET_STATUS,
  SET_ALL_STATUSES,
  SET_STATUS_LOADING
} from './types';

export const saveStatusAction = status => ({ type: SET_STATUS, payload: status });

export const saveStatusesAction = allStatuses => ({ type: SET_ALL_STATUSES, payload: allStatuses });

export const statusesLoadingAction = statusLoading => ({ type: SET_STATUS_LOADING, payload: statusLoading });
