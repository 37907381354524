import {
  SET_ORDERS,
  SET_ALL_ORDERS,
  SET_ORDERS_LOADING
} from './types';

export const saveOrderAction = order => ({ type: SET_ORDERS, payload: order });

export const saveOrdersAction = allOrders => ({ type: SET_ALL_ORDERS, payload: allOrders });

export const ordersLoadingAction = orderLoading => ({ type: SET_ORDERS_LOADING, payload: orderLoading });
