import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './CarouselItemContent.module.scss';
import defaultImg from '../../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';

function CarouselItemContent({ slide }) {
  const SLIDE_ROUTE = slide.slideType === 'product' ? 'products/item/' : 'blog/';
  
  // Ensure "undefined" strings are treated as invalid image sources
  const isValidImage = (image) => image && image !== "undefined";
  
  const imageSrc = isValidImage(slide.imageFirst) ? slide.imageFirst 
                    : isValidImage(slide.imageUrl) ? slide.imageUrl 
                    : defaultImg;

  const altText = slide.description || `Slide related to ${slide.slideType}`;

  return (
    <div className={styles.carouselItemWrapper}>
      <NavLink to={`/${SLIDE_ROUTE}${slide._id}`} target="_blank">
        <img
          className={`${styles.carouselImage} d-block w-100`}
          src={imageSrc}
          alt={altText}
          loading="lazy"
        />
      </NavLink>
    </div>
  );
}

export default CarouselItemContent;
