import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './Crumbs.module.scss';

const Crumbs = ({ category, product }) => {
  return (
    <div className={styles.breadCrumbs}>
      <NavLink className={styles.crumbsLink} to='/'>
        Головна
      </NavLink>

      {category && category.name && (
        <>
          <span className={styles.iconBreadcrumbs}>{}</span>
          <span className={styles.crumbs}>{category.name}</span>
        </>
      )}

      {category && category.length === 0 && (
        <>
          <span className={styles.iconBreadcrumbs}>{}</span>
          <span className={styles.crumbs}>Всі товари</span>
        </>
      )}

      {product && (
        <>
          <span className={styles.iconBreadcrumbs}>{}</span>
          <NavLink className={styles.crumbsLink} to={`/products/categories/${product.categories.title}`}>
            {product.categories.name}
          </NavLink>
          <span className={styles.iconBreadcrumbs}>{}</span>
          <span className={styles.crumbs}>{product.name}</span>
        </>
      )}
    </div>
  );
};

Crumbs.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    categories: PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Crumbs.defaultProps = {
  category: null,
  product: null,
};

export default Crumbs;
