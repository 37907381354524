/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import {
  addProductToCart,
  decreaseCartProductQuantity,
  deleteProductFromCart,
  getCart,
  updateCart,
} from '../../http/cartAPI';
import { calculateTotalPrice } from '../../utils/func';
import { saveModalOrderAction } from '../modal/actions';
import { cartLoadingAction, cartTotalPriceAction, saveCartAction, saveLocalCartAction } from './actions';

// Отримати всю корзину
export const getCartOperation = () => dispatch => {
  dispatch(cartLoadingAction(true));

  // Імітація отримання кошика з localStorage
  if (!localStorage.getItem('cart')) {
    localStorage.setItem('cart', JSON.stringify([]));
  }

  dispatch(cartLoadingAction(false));

  const localCart = JSON.parse(localStorage.getItem('cart'));
  if (localCart) {
    dispatch(saveLocalCartAction(localCart));
    dispatch(setCartTotalPriceOperation(localCart));
  }
};

// Зменшити кількість товару в кошику
export const decreaseCartProductQuantityOperation = (admin, id) => dispatch => {
  decreaseCartProductQuantity(admin, id).then(res => {
    dispatch(saveCartAction(res.data));
  });
};

// Видалити товар з кошика
export const deleteProductFromCartOperation = (admin, id, cart) => dispatch => {
  // deleteProductFromCart(admin, id).then(res => {
  //   if (cart.products.length === 1) {
  //     dispatch(saveCartAction(null));
  //     dispatch(saveModalOrderAction(false));
  //   } else {
  //     dispatch(saveCartAction(res.data));
  //   }
  // });
};

// Додати товар в кошик
export const addProductToCartOperation = (admin, id) => dispatch => {
  addProductToCart(admin, id).then(res => {
    dispatch(saveCartAction(res.data));
  });
};

// Оновити кошик
export const updateCartOperation = (admin, products) => dispatch => {
  // dispatch(cartLoadingAction(true));
  // updateCart(admin, products).then(res => {
    // dispatch(saveCartAction(res.data));
    // dispatch(cartLoadingAction(false));
    // dispatch(setCartTotalPriceOperation(res.data));
  // });
};

// Операція для зміни локального кошика
export const changeLocalCartOperation = (id, method) => dispatch => {
  let localCart = JSON.parse(localStorage.getItem('cart')) || [];

  // Обробка продуктів в кошику
  let newLocalCart = localCart.map(product => {
    if (product?.product._id === id) {
      if (method === 'decrease') {
        product.cartQuantity = Math.max(product.cartQuantity - 1, 0);
      } else if (method === 'add') {
        product.cartQuantity += 1;
      } else if (method === 'delete') {
        return null;
      }
    }
    return product;
  });

  // Фільтруємо продукти, які не є null (якщо видаляється)
  newLocalCart = newLocalCart.filter(el => el !== null && el.cartQuantity > 0);

  // Оновлюємо localStorage
  localStorage.setItem('cart', JSON.stringify(newLocalCart));

  // Оновлюємо загальну ціну
  const totalPrice = calculateTotalPrice({ products: newLocalCart });
  dispatch(cartTotalPriceAction(totalPrice));

  // Зберігаємо оновлений кошик в Redux
  dispatch(saveLocalCartAction(newLocalCart));
};

// Встановлення загальної ціни кошика
export const setCartTotalPriceOperation = products => dispatch => {
  const totalPrice = calculateTotalPrice(products);
  dispatch(cartTotalPriceAction(totalPrice));
};
