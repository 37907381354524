import React from "react";
import { NavLink } from "react-router-dom";
import Icons from "../../Icons/Icons";
import OrderModal from "../../OrderModal/OrderModal";
import "./NavList.scss";
import { useDispatch, useSelector } from "react-redux";
import { orderOpenOperation } from "../../../store/modal/operations";
import { getModalOrderSelector } from "../../../store/modal/selectors";

const NavList = React.memo(({ items, wishlist, cart, btnDisable = false, color = "#000", orderOnly = false, header = false }) => {
    const dispatch = useDispatch();
    const isOrderModalOpen = useSelector(getModalOrderSelector);

    const openModalOrder = () => {
        dispatch(orderOpenOperation());
    };

    const hasWishlistProducts = !!wishlist && wishlist?.products?.length > 0;
    const hasCartItems = !!cart && cart.length > 0;

    const itemsArray = items.map((item) => {

            if (item.name === "Auth" && !btnDisable) {
                return (
                    <li className="link__wrapper-icon" key={item.name}>
                        <NavLink className="link__wrapper-item" to={item.url}>
                            <Icons type={item.name.toLowerCase()} color={color} width={30} height={30} />
                        </NavLink>
                    </li>
                );
            }

            if (item.name === "Wishlist" && hasWishlistProducts  && !btnDisable) {
                return (
                    <li className="link__wrapper" key={item.name}>
                        <NavLink className="link__wrapper-item" to={item.url}>
                            <Icons type={item.name.toLowerCase()} color={color} width={30} height={30} />
                            {hasWishlistProducts && <small className="small__number">{wishlist.products.length}</small>}
                        </NavLink>
                    </li>
                );
            }

            if (item.name === "Orders" && (!orderOnly)) {
                return (
                    <li className="link__wrapper" key={item.name}>
                        <span className="link__wrapper-item" onClick={openModalOrder}>
                            <Icons type={item.name.toLowerCase()} color={color} width={30} height={30} />
                            {hasCartItems && <small className="small__number">{cart.length}</small>}
                        </span>
                        {isOrderModalOpen && <OrderModal />}
                    </li>
                );
            }

            if (item.name === "Account" && !btnDisable) {
                return (
                    <li className="link__wrapper-icon" key={item.name}>
                        <NavLink className="link__wrapper-item" to={item.url}>
                            <Icons type={item.name.toLowerCase()} color={color} width={30} height={30} />
                        </NavLink>
                    </li>
                );
            }

        return null;
    });

    return <ul className="links__container">{itemsArray}</ul>;
});

export default NavList;
