import React from "react";
import styles from "./Burger.module.scss";
import Icons from "../../../Icons/Icons";

const Burger = ({ open, setOpen}) => {
  const buttonClass = open ? `${styles.burger_button} ${styles.open}` : `${styles.burger_button}`;
  return (
    <>
      <Icons type="filter" width={30} height={30} className={styles.pointer} color="#727272" onClick={() => setOpen(!open)}/>
      <button className={buttonClass} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </button>
    </>
  );
};

export default Burger;