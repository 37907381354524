import React, { useEffect, useState } from "react";
import './Header.scss';
import NavList from "./NavList/NavList";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import BurgerMenuProducts from "../BurgerMenuProducts/BurgerMenu";
import { useHttp } from "../../hooks/http.hook";
import { getWishListSelector } from "../../store/wishList/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getDataOperation } from "../../store/operations";
import { getLocalCartSelector } from "../../store/cart/selectors";
import MySearch from "../MyForms/MySearch/MySearch";
import { saveCategoriesMenuAction } from "../../store/categories/actions";
import logo from '../../theme/logo/jado_light.png';
import { checkAuth } from "../../utils/auth/checkAuth";
import { useAuth } from "../../utils/auth/isAuth";
import { useLogout } from "../../utils/auth/logout";
import useAuthLinks from "../../utils/auth/authLinks";
import useIsMobile from "../../utils/auth/useIsMobile";

const Header = () => {
    const auth = useAuth(); // Now it's valid
    const logout = useLogout();
    const dispatch = useDispatch();
    const links = useAuthLinks();
    const wishlist = useSelector(getWishListSelector);    
    const localCart = useSelector(getLocalCartSelector);    
    const { request } = useHttp();
    const [menu, setMenu] = useState([{ name: "products", title: 'products', url: "products" }]);
    const isMobile = useIsMobile(); // Use the custom hook here

    // Fetch categories for the burger menu
    const getCategories = async () => {
        try {
            const data = await request('openpath/categories/getall/ismenu/', 'GET');
            if (data.status) {
                setMenu(data.data);
                dispatch(saveCategoriesMenuAction(data.data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch initial data
    useEffect(() => {
        getCategories();
    }, []);

    // Fetch data on authentication status change
    useEffect(() => {
        dispatch(getDataOperation(auth.isAuthenticated, auth.permissions?.admin?.access));
    }, [auth.isAuthenticated, auth.permissions]);
      
    // If the wishlist count is not updating, ensure `wishlist.products` exists
    const wishlistCount = wishlist?.products?.length || 0;

    return (
        <header className="header__container">
            <div className="header__catalogs">
                <BurgerMenuProducts menu={menu} />
            </div>
            <Link className="logo__name" to="/index">
                <span className="logo__namehidden">
                    {/* {config.NAMESHOP}{auth.permissions?.admin?.access && "ADMIN"} */}
                    <picture>
                        <img className="logo__img" src={logo} alt="logo" />
                    </picture>
                </span>
                {/* <span className="logo__icon">
                    <picture>
                        <img className="logo__img" src={logo} alt="logo" />
                    </picture>
                </span> */}
            </Link>
            <MySearch />
           {!isMobile && <div className="d-flex">
                <NavList key={"header"} items={links} wishlist={wishlist} cart={localCart} header={true} btnDisable={false}/>
                {auth.isAuthenticated && (
                    <div className="logo__exit" onClick={logout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                        </svg>
                    </div>
                )}
            </div>}
            {isMobile && <div className="d-flex"><NavList key={"header"} items={links} wishlist={wishlist} cart={localCart} ororderOnly={true} header={true} btnDisable={true}/></div>}
        </header>
    );
};

export default Header;
