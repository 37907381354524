import React from "react";
import Menu from "./Menu/Menu"
import Burger from "./Burger/Burger"
import styles from "./FilterModal.module.scss";

const FilterModal = ({filterProducts, filter, selected, ordersFilter}) => {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    return (
        <>
            <div className={styles.filter} ref={node}>
                <Burger open={open} setOpen={setOpen}/>
                <Menu open={open} setOpen={setOpen} filterProducts={filterProducts} filter={filter} selected={selected} ordersFilter={ordersFilter}/>
            </div>
        </>
    );
};

export default FilterModal;