import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Error500 from '../Error500/Error500';

const ErrorBoundary = ({ children }) => {
  const [errorPresent, setErrorPresent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLocationChange = () => {
      setErrorPresent(false);
    };

    const unlisten = () => navigate(handleLocationChange);

    return () => unlisten();
  }, []);

  useEffect(() => {
    const handleComponentError = () => {
      setErrorPresent(true);
    };

    window.addEventListener('error', handleComponentError);

    return () => window.removeEventListener('error', handleComponentError);
  }, []);

  const handleErrorBoundaryError = () => {
    setErrorPresent(true);
  };

  if (errorPresent) {
    return <Error500 />;
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
