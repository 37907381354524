import React, { useState } from 'react';
import styles from './CarouselSettings.module.scss';
import axios from 'axios';

const AddSlideForm = ({ selectedCaruselId, products, blogs, fetchCaruselItems, config }) => {
  const [newSlide, setNewSlide] = useState({ itemId: '', itemType: 'blog', order: 1 });

  const handleAddSlide = async () => {
    if (!selectedCaruselId) {
      alert('Спочатку оберіть або створіть карусель');
      return;
    }
    try {
      await axios.post(
        '/api/admin/carusels/add-slide',
        {
          caruselId: selectedCaruselId,
          itemId: newSlide.itemId,
          itemType: newSlide.itemType,
          order: newSlide.order,
        },
        config
      );
      fetchCaruselItems();
    } catch {
      alert('Помилка при додаванні слайду');
    }
  };

  return (
    <div className={styles.addSlide}>
      <h2>Додати новий слайд</h2>
      <select
        value={newSlide.itemType}
        onChange={(e) => setNewSlide({ ...newSlide, itemType: e.target.value })}
      >
        <option value="blog">Блог</option>
        <option value="product">Продукт</option>
      </select>

      <div className={styles.customSelect}>
            {newSlide.itemType === 'product' ? (
                <div className={styles.dropdown}>
                <button className={styles.dropdownButton}>
                    {newSlide.itemId ? 
                    products.find((product) => product._id === newSlide.itemId)?.name || "Оберіть продукт" 
                    : "Оберіть продукт"}
                </button>
                <div className={styles.dropdownContent}>
                    {products.map((product) => (
                    <div
                        key={product._id}
                        onClick={() => setNewSlide({ ...newSlide, itemId: product._id })}
                        className={`${styles.option} ${
                        newSlide.itemId === product._id ? styles.selected : ''
                        }`}
                    >
                        <img src={product.imageFirst} alt={product.name} className={styles.thumbnail} />
                        <span>{product.name}</span>
                    </div>
                    ))}
                </div>
                </div>
            ) : (
                <div className={styles.dropdown}>
                <button className={styles.dropdownButton}>
                    {newSlide.itemId ? 
                    blogs.find((blog) => blog._id === newSlide.itemId)?.title || "Оберіть блог" 
                    : "Оберіть блог"}
                </button>
                <div className={styles.dropdownContent}>
                    {blogs.map((blog) => (
                    <div
                        key={blog._id}
                        onClick={() => setNewSlide({ ...newSlide, itemId: blog._id })}
                        className={`${styles.option} ${
                        newSlide.itemId === blog._id ? styles.selected : ''
                        }`}
                    >
                        <img src={blog.imageUrl} alt={blog.title} className={styles.thumbnail} />
                        <span>{blog.title}</span>
                    </div>
                    ))}
                </div>
                </div>
            )}
            </div>
      <h3>Виберіть порядковий номер</h3>
      <input
        type="number"
        placeholder="Порядок"
        value={newSlide.order}
        onChange={(e) => setNewSlide({ ...newSlide, order: parseInt(e.target.value, 10) })}
        className={styles.input}
      />
      <button onClick={handleAddSlide} className={styles.button}>Додати слайд</button>
    </div>
  );
};

export default AddSlideForm;
