import { useMemo } from "react";
import { useAuth } from "./isAuth";
import { checkAuth } from "./checkAuth";

const useAuthLinks = () => {
    const auth = useAuth(); // Now it's valid
    // Використовуємо useMemo для мемоізації результату
    const links = useMemo(() => {
        return checkAuth(auth);
    }, [auth, auth.isAuthenticated, auth.permissions]);

    return links;
};

export default useAuthLinks;
