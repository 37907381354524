import React from 'react';

export const auth = (color = '#797878', filled = false, width = 25, height = 25) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={width} height={height}
  viewBox="0 0 24 24"
  strokeWidth="1.5"
  strokeLinecap="round"
  strokeLinejoin="round"
  stroke={color}
  fill={filled ? color : 'none'}
>
  <circle cx="12" cy="8" r="4" />
  <path d="M4 20c0-4 4-7 8-7s8 3 8 7" />
</svg>

);