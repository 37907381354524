import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getDate } from '../../../../utils/func';
import styles from './Order.module.scss';
import { Button } from 'react-bootstrap';

const PRODUCT_ROUTE = "products/item/";

const Order = ({ order }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const content = useRef(null);

  const {
    status,
    firstName,
    email,
    phone,
    lastName,
    orderNo,
    date,
    totalSum,
    paymentMethod,
    deliveryAddress,
    products,
  } = order;

  const statusColors = {
    specified: { color: '#FF0000', text: 'Уточнюється' },
    processed: { color: '#FF8C00', text: 'Обробляється' },
    send: { color: '#0000FF', text: 'Відправлено' },
    completed: { color: '#008000', text: 'Виконано' },
    abolition: { color: 'red', text: 'Відмінено' },
  };

  const statusElement = (
    <p style={{ color: statusColors[status]?.color || 'black' }}>
      {statusColors[status]?.text || 'Невідомий статус'}
    </p>
  );

  const toggleDescription = () => {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  };

  const orderedProducts = products.map(({ product, cartQuantity }) => (
    <article key={product._id} className={styles.productItem}>
      {!product.itsBottle && <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} className={styles.productLink}>
        <img src={product.imageFirst} width={100} alt={`${product.name}`} />
      </NavLink>}
      <div className={styles.productDescription}>
        <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} className={styles.productLink}>
          {product.itsBottle ? 'Флакон' : product.name}
        </NavLink>
        <p>Ціна: {product.currentPrice} грн</p>
        <p>
          К-ть:{' '}
          {product.bottling ? (
            <span className={styles.bold}>
              {Math.floor(cartQuantity) + 1} {product.itsBottle ? 'шт.' : 'мл.'}
            </span>
          ) : (
            <span className={styles.bold}>{cartQuantity} шт.</span>
          )}
        </p>
      </div>
    </article>
  ));

  return (
    <section className={`${styles.orderWrapper} ${active ? styles.active : ''}`}>
      <header className={styles.orderHeader}>
        <div>Замовлення №{orderNo}</div>
        {/* <div><p>{totalSum.$numberDecimal} грн</p></div> */}
      </header>
      <div className={styles.orderDetails}>
        <div>
          {products.map(({ product }) => (
            <p key={product._id} className={styles.productName}>
              {product.name}
            </p>
          ))}
        </div>
        <div>
          <span className={styles.date}>Дата замовлення: {getDate(date)}</span>
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant="dark" onClick={toggleDescription}>
            Детальна інформація
          </Button>
        </div>
      </div>
      <div ref={content} style={{ height }} className={styles.orderDescription}>
        <hr />
        <section className={styles.userDataTable}>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Статус</p>
            {statusElement}
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Ім'я та прізвище</p>
            <p>{`${firstName} ${lastName}`}</p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Електронна пошта</p>
            <p>{email}</p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Номер телефону</p>
            <p>{phone}</p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Місто</p>
            <p style={{ color: deliveryAddress.city ? 'inherit' : 'red' }}>
              {deliveryAddress.city || 'Місто не вказане'}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Область</p>
            <p style={{ color: deliveryAddress.region ? 'inherit' : 'red' }}>
              {deliveryAddress.region || 'Область не вказана'}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Адреса</p>
            <p style={{ color: deliveryAddress.address ? 'inherit' : 'red' }}>
              {deliveryAddress.address || 'Адреса не вказана'}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Спосіб доставки</p>
            <p style={{ color: deliveryAddress.delivery ? 'inherit' : 'red' }}>
              {deliveryAddress.delivery || 'Спосіб доставки не вказаний'}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableRowTitle}>Спосіб оплати</p>
            <p>{paymentMethod}</p>
          </div>
        </section>
        <section className={styles.productPreview}>
          <h2>Ваше замовлення</h2>
          <hr />
          {orderedProducts}
          <p className={styles.totalPrice}>
            Разом до оплати: {totalSum.$numberDecimal} грн
          </p>
        </section>
      </div>
    </section>
  );
};

export default Order;
