import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { replace } from '../../../../utils/func';
import ProductOrdersItem from '../ProductOrdersItem/ProductOrdersItem';
import SpecifyOrder from '../SpecifyOrder/SpecifyOrder';
import styles from './OrdersInfo.module.scss';

const OrdersInfo = ({ order, OrderState }) => {
  const [specifyOrderState, setspecifyOrderState] = useState(false);
  const [orderState, setOrderState] = useState(order);
  const { email, phone, paymentMethod, deliveryAddress, totalSum, comment, products, status, _id: id } = orderState;

  const productList = products.map(p => {
    return (
      <li key={p.product.itemNo}>
        <ProductOrdersItem product={p} />
      </li>
    );
  });

  return (
    <div className={styles.info}>
      {OrderState === 'specified' && (
        <div className={styles.specify} onClick={() => setspecifyOrderState(!specifyOrderState)}>
          Уточнити замовлення
        </div>
      )}
      <div>
        Email : <span className={styles.bold}>{email}</span>
      </div>
      <div>
        Номер телефона: <span className={styles.bold}>{phone}</span>
      </div>
      {specifyOrderState ? (
        <SpecifyOrder
          orderId={id}
          order={order}
          setspecifyOrderState={setspecifyOrderState}
          setOrderState={setOrderState}
        />
      ) : (
        <div>
          <div>
            Спосіб оплати: <span className={styles.bold}>{paymentMethod}</span>
          </div>
          <div>
            Спосіб доставки: <span className={styles.bold}>{deliveryAddress?.delivery}</span>
          </div>
          {deliveryAddress && (
            <div>
              Адреса доставки:{' '}
              <span className={styles.bold}>
                {deliveryAddress?.region} обл., {deliveryAddress?.city}, {deliveryAddress?.address}.
              </span>
            </div>
          )}
          <div>
            Коментарій: <span className={styles.bold}>{comment}</span>
          </div>
        </div>
      )}

      <div>
        Сума до сплати: <span className={styles.bold}>{replace(totalSum.$numberDecimal)} грн.</span>
      </div>
      <div>
        Замовлення:{` `}
        <ul className={styles.productList}>{productList}</ul>
      </div>
    </div>
  );
};

OrdersInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrdersInfo;
