import React from "react";
import '../Registration/Registration.scss';
import SignInForm from "../../../components/Auth/SignInForm/SignInForm";

const SignIn = () =>  {
    return (
        <>
            <SignInForm/>
        </>
    );
}

export default SignIn;