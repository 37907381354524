import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux' 
import {BrowserRouter} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/Error/ErrorBoundary/ErrorBoundary'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/styles/global.scss';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>

  //  </React.StrictMode> 
);

reportWebVitals();