import React from "react";
import Section from "./Section";
import { contractData } from "./contractData";
import styles from "./ContractPage.module.scss";

const ContractPage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</h1>
      {contractData.map((section) => (
        <Section key={section.id} title={section.title} content={section.content} />
      ))}
      <footer className={styles.footer}>
        <p>© {new Date().getFullYear()} ФОП Полтавець Марія Вікторівна. Всі права захищені.</p>
      </footer>
    </div>
  );
};

export default ContractPage;
