
import { useState, useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const auth = useContext(AuthContext);

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}, params = null) => {
      setLoading(true);
      try {
        let useParams = ""
        if (body) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }
        if(auth.isAuthenticated){
          headers["Authorization"] = `Bearer ${auth.token}`
        }
        if(params != null ){
          useParams = `?${new URLSearchParams(params).toString()}`
        }
      

        const response = await fetch(`/api/${url}${useParams}`, { method, body, headers });
        const data = await response.json();
        // const jsonString = '{"message": "Success"}';
        // const data = JSON.parse(jsonString);
        if (!response.ok) {
          throw new Error(data.message || "Щось не так:(");
        }

        setLoading(false);

        return data;
      } catch (e) {
        setLoading(false);
        setError(e.message);
        throw e;
      }
    }, [] 
  );
  const clearError = useCallback(() => setError(null), []);
  return { loading, request, error, clearError };
};