import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../components/Container/Container';
import { getUserIsAuthSelector, getUserIsLoadingSelector} from '../../store/user/selectors';
import CheckoutAuth from './CheckoutAuth/CheckoutAuth';
import CheckoutNoAuth from './CheckoutNoAuth/CheckoutNoAuth';
import OrderPreview from './OrderPreview/OrderPreview';
import Loader from '../../components/Loader/Loader';
import styles from './Orders.module.scss';
import { checkAuthOperation } from '../../store/user/operations';
import { AuthContext } from '../../context/AuthContext';

const Order = () => {
  const dispatch = useDispatch();
  const admin = useContext(AuthContext).permissions?.admin?.access;
  const isAuth = useContext(AuthContext).isAuthenticated;
//   const isAuth = useSelector(getCustomerIsAuthSelector);
  const customerLoading = useSelector(getUserIsLoadingSelector);

  useEffect(() => {
    dispatch(checkAuthOperation(admin));
  }, []);

  if (customerLoading) {
    return <Loader />;
  }

  return (
    // <main>
      <Container>
        <p className={styles.pageTitle}>Оформлення замовлення</p>
        <div className={styles.pageContainer}>
          <div className={styles.ordersCantainer}>{isAuth ? <CheckoutAuth /> : <CheckoutNoAuth />}</div>
          {/* <CheckoutAuth /> */}
          <OrderPreview />
        </div>
      </Container>
    // </main> 
  );
};

export default Order;