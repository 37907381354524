import React from "react";
import Burger from "./Burger/Burger"
import MenuProducts from "./MenuProducts/MenuProducts";

const BurgerMenu = ({menu = []}) => {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    return (
        <>
            <Burger open={open} setOpen={setOpen}/>
            <div ref={node} onMouseLeave={() => setOpen(false)}>
                <MenuProducts open={open} setOpen={setOpen} menuList={menu}/>
            </div>
        </>
    );
};

export default BurgerMenu;