import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError } from '../../../hooks/message.hook';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import ProductInfo from '../../../components/product/ProductInfo/ProductInfo';
import { deleteProductFromWishlist, getWishlist } from '../../../http/wishlistAPI';
import { useDispatch } from 'react-redux';
import { AuthContext } from '../../../context/AuthContext';
import { updateWishListOperation } from '../../../store/wishList/operations';
import styles from "./Wishlist.module.scss";

const Wishlist = () => {
    const dispatch = useDispatch();
    const { request } = useHttp();
    const messageError = useMessageError();
    const [wishlist, setWishlist] = useState([]);
    const auth = useContext(AuthContext);
    const admin = auth.permissions?.admin?.access;

    const fetchAll = async () => {
        try {
            const data = await request('customer/wishlist/getall', 'GET');
            setWishlist(data.data);
            localStorage.setItem('wishlist', JSON.stringify(data.data));
        } catch (error) {
            messageError(error);
        }
    };

    const deleteProduct = async (id) => {
        try {
            await deleteProductFromWishlist(id);
            const updatedWishlist = wishlist.products.filter(product => product._id !== id);
            const result = { ...wishlist, products: updatedWishlist };
            setWishlist(result);
            fetchAll();
            dispatch(updateWishListOperation(admin, result));
            localStorage.setItem('wishlist', JSON.stringify(result));
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        fetchAll();
    }, []);

    const hasProducts = wishlist && wishlist.products && wishlist.products.length > 0;

    return (
        <section>
            <BurgerMenu />
            <Container>
                <h2 className="pt-4">Всі вподобані товари</h2>
                <p>Кількість: <span>{hasProducts ? wishlist.products.length : 0}</span></p>
                {!hasProducts ? (
                    <p>Немає жодних товарів у вашому списку бажань.</p>
                ) : (
                    <Row className="gy-4">
                        {wishlist.products.map((product, index) => (
                            <Col
                                key={product._id}
                                xs={12} sm={6} md={6} lg={4} xl={3} // Adjusts columns per row based on screen size
                                className={`${styles.cartItem} ${product.enabled ? "" : styles.bgDisabled}`}
                            >
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <ProductInfo product={product} />
                                    <Button
                                        variant="danger"
                                        className={styles.itemDel}
                                        onClick={() => deleteProduct(product._id)}
                                    >
                                        X
                                    </Button>
                                </div>
                                <p className="text-muted">Товар #{index + 1}</p>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </section>
    );
};

export default Wishlist;
