import React from 'react';
import styles from './CarouselSettings.module.scss';

const CarouselList = ({ carusels, selectedCaruselId, setSelectedCaruselId }) => {
  return (
    <div className={styles.carouselSelection}>
      <h2>Список каруселей</h2>
      <select
        value={selectedCaruselId}
        onChange={(e) => setSelectedCaruselId(e.target.value)}
        className={styles.select}
      >
        <option value="">Оберіть карусель</option>
        {carusels.map((carusel) => (
          <option key={carusel._id} value={carusel._id}>
            {carusel.title}
          </option>
        ))}
      </select>
      {!selectedCaruselId && (
        <p className={styles.errorMessage}>Спочатку оберіть або створіть карусель</p>
      )}
    </div>
  );
};

export default CarouselList;
