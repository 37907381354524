import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { replace } from '../../../../utils/func';
import styles from './ProductOrdersItem.module.scss';
import Decimal from 'decimal.js';
import defaultImg from '../../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';

const PRODUCT_ROUTE = "products/item/";

const ProductOrdersItem = ({ product }) => {

  // Функція обчислення загальної суми для продукту на основі discountedPrice
  const calculateTotalPrice = () => {
    // Використовуємо discountedPrice, який вже має обчислену знижку
    let totalPrice = new Decimal(product.discountedPrice).mul(product.cartQuantity).toDecimalPlaces(2).toString();
    return totalPrice;
  };

  return (
<article className={styles.productItem}>
  <figure className={styles.productImage}>
    <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
      <img 
        src={product.product.imageFirst ? product.product.imageFirst : `${defaultImg}`} 
        width={100} 
        height={100} 
        alt="productImage" 
      />
    </NavLink>
  </figure>
  <div>
    <NavLink to={`/${PRODUCT_ROUTE}${product.product._id}`}>
      {!product.product.itsBottle ? (
        <h2 className={`${styles.bold} ${styles.productName}`}>
          {product.product.name}, {product.product.color}
        </h2>
      ) : (
        <h2 className={`${styles.bold} ${styles.productName}`}>
          Флакон
        </h2>
      )}
    </NavLink>
    <section className={styles.productPrice}>
      <p>
        <strong>Ціна:</strong>{' '}
        <span className={styles.bold}>{replace(product.discountedPrice)} грн.</span>
      </p>
      <p>
        <strong>К-ть:</strong>{' '}
        {product.product.bottling ? (
          <span className={styles.bold}>
            {Math.floor(product.cartQuantity) + 1} {product.product.itsBottle ? 'шт.' : 'мл.'}
          </span>
        ) : (
          <span className={styles.bold}>{product.cartQuantity} шт.</span>
        )}
      </p>
      <p>
        <strong>Сума:</strong>{' '}
        <span className={styles.bold}>{calculateTotalPrice()} грн.</span>
      </p>
    </section>
  </div>
</article>

  );
};

ProductOrdersItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductOrdersItem;
