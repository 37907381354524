import { getAdminProducts, getAdminProductById, getProducts, getProductById,getAdminPanelProducts,getAdminPanelProductById } from '../../http/productAPI';
import { saveloaderAction } from '../loader/actions';
import {
  productsLoadingAction,
  saveProductsAction,
  saveOneProductAction,
  oneProductLoadingAction,
  saveProductsQuantityAction,
  saveProductsFilterAction,
  saveProductsFilteredAction,
  saveAdminProductsAction,
  saveAdminOneProductAction,
  saveAdminPanelProductsAction,
  saveAdminPanelOneProductAction
} from './actions';

export const getAdminProductsOperation = (filters) => dispatch => {
  dispatch(saveloaderAction(true));
  dispatch(productsLoadingAction(true));
  getAdminProducts(filters).then(res => {
    dispatch(saveAdminProductsAction(res.data));
    dispatch(productsLoadingAction(false));
    dispatch(saveloaderAction(false));
  }).catch(err => {
    dispatch(oneProductLoadingAction(false));
    dispatch(saveloaderAction(false));
    dispatch(productsLoadingAction(false));
    return err;
  });
};
export const getAdminPanelProductsOperation = () => dispatch => {
  dispatch(saveloaderAction(true));
  dispatch(productsLoadingAction(true));
  getAdminPanelProducts().then(res => {
    dispatch(saveAdminPanelProductsAction(res.data));
    dispatch(productsLoadingAction(false));
    dispatch(saveloaderAction(false));
  }).catch(err => {
    dispatch(saveloaderAction(false));
    dispatch(productsLoadingAction(false));
    return err;
  });
};

export const getAdminOneProductOperation = () => dispatch => {
  dispatch(saveloaderAction(true));
  dispatch(productsLoadingAction(true));
  getAdminProductById().then(res => {
    dispatch(saveAdminOneProductAction(res.data));
    dispatch(productsLoadingAction(false));
    dispatch(saveloaderAction(false));
  }).catch(err => {
    dispatch(oneProductLoadingAction(false));
    dispatch(saveloaderAction(false));
    dispatch(productsLoadingAction(false));
    return err;
  });
};
export const getAdminPanelOneProductOperation = () => dispatch => {
  dispatch(saveloaderAction(true));
  dispatch(productsLoadingAction(true));
  getAdminPanelProductById().then(res => {
    dispatch(saveAdminPanelOneProductAction(res.data));
    dispatch(productsLoadingAction(false));
    dispatch(saveloaderAction(false));
  }).catch(err => {
    dispatch(oneProductLoadingAction(false));
    dispatch(saveloaderAction(false));
    dispatch(productsLoadingAction(false));
    return err;
  });
};
export const getProductsOperation = ( filter ) => dispatch => {
 if(filter){  
    dispatch(saveloaderAction(true));
    dispatch(productsLoadingAction(true));
    getProducts( filter).then(res => {
      dispatch(saveProductsAction(res.data));
      dispatch(productsLoadingAction(false));
      dispatch(saveloaderAction(false));
    }).catch(err => {
      dispatch(productsLoadingAction(false));
      dispatch(saveloaderAction(false));
      return err;
    });
  }
};

export const getOneProductOperation = id => dispatch => {
  dispatch(saveloaderAction(true));
  dispatch(oneProductLoadingAction(true));
  getProductById(id).then(res => {
    dispatch(saveOneProductAction(res.data.data));
    dispatch(oneProductLoadingAction(false));
    dispatch(saveloaderAction(false));
  }).catch(err => {
    dispatch(oneProductLoadingAction(false));
    dispatch(saveloaderAction(false));
    return err;
  });
};

// export const getProductsSearchOperation = (search) => dispatch => {
//   dispatch(saveloaderAction(true));
//   dispatch(productsLoadingAction(true));
//   searchProducts(search).then(res => {
//     dispatch(saveProductsAction(res.data));
//     dispatch(productsLoadingAction(false));
//     dispatch(saveloaderAction(false));
//   }).catch(err => {
//     dispatch(oneProductLoadingAction(false));
//     dispatch(saveloaderAction(false));
//     return err;
//   });
// };
// export const getOneProductUrlOperation = productUrl => dispatch => {
//   dispatch(oneProductLoadingAction(true));
//   getProductByUrl(productUrl).then(res => {
//     dispatch(saveOneProductAction(res.data));
//     dispatch(oneProductLoadingAction(false));
//   });
// };

// export const getProductsFilterOperation = ({ navigate, ...filters }) => dispatch => {
//   dispatch(productsLoadingAction(true));

//     dispatch(saveProductsFilterAction({ ...filters }));

//     const { categories, ...Currentfilters } = filters;
//     const currentUrlParams = new URLSearchParams(Currentfilters);
//     navigate(`${window.location.pathname}?${currentUrlParams}`);

//     getProductsFilterParams(filters).then(res => {
//       dispatch(saveProductsFilteredAction(res.data.products));
//       dispatch(saveProductsQuantityAction(res.data.productsQuantity));

//       dispatch(productsLoadingAction(false));
//     });
//   };
