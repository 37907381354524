import React, { useState } from 'react';
import styles from './CarouselSettings.module.scss';
import axios from 'axios';

const CreateCarouselForm = ({ onSuccess, config }) => {
  const [newCaruselTitle, setNewCaruselTitle] = useState('');
  const handleCreate = async () => {
    if (!newCaruselTitle.trim()) {
      alert('Назва каруселі не може бути порожньою');
      return;
    }
    try {
      await axios.post('/api/admin/carusels/create', { title: newCaruselTitle }, config);
      setNewCaruselTitle('');
      onSuccess();
    } catch {
      alert('Помилка при створенні каруселі');
    }
  };

  return (
    <div className={styles.createCarusel}>
      <h2>Створити нову карусель</h2>
      <input
        type="text"
        placeholder="Назва каруселі"
        value={newCaruselTitle}
        onChange={(e) => setNewCaruselTitle(e.target.value)}
      />
      <button onClick={handleCreate} className={styles.button}>Створити</button>
    </div>
  );
};

export default CreateCarouselForm;
