import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { getAllOrders } from '../../../http/ordersAPI';
import Pagination from '../../../components/Pagination/Pagination';
import OrdersItem from '../OrdersItem/OrdersItem';
import styles from './OrdersList.module.scss';
import { Button,
  //  Pagination 
  } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import MyTextInput from '../../MyForms/MyTextInput/MyTextInput';

const OrdersList = () => {
  const admin = useContext(AuthContext).permissions?.admin?.access;
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [refreshOrders, setRefreshOrders] = useState(true);
  const [statusFilter, setStatusFilter] = useState('Усі замовлення');
  const [startPage, setStartPage] = useState(1);
  const [ordersQuantity, setOrdersQuantity] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const perPage = 10;

  let ordersFilter = {};
  if (statusFilter === 'Усі замовлення' && searchInput === '') {
    ordersFilter = { perPage, startPage, sort: '-date' };
  } else {
    ordersFilter = { perPage, startPage, sort: '-date', status: statusFilter, find:searchInput };
  }

  const search = (event) => {
    setSearchInput(event);
    setRefreshOrders(true);
  }

  useEffect(() => {
    setOrdersLoading(true);
    getAllOrders(admin, ordersFilter).then(res => {
      setOrders(res.data.orders);
      setOrdersQuantity(res.data.ordersQuantity);
      setRefreshOrders(false);
      setOrdersLoading(false);
    });
  }, [refreshOrders, startPage]);
  let ordersList = null;
  if (!ordersLoading) {
    ordersList = orders.map(order => {
      return (
        <li key={order.orderNo}>
          <OrdersItem order={order} />
        </li>
      );
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
         <div className={styles.filterWrapper}>
            <label>Пошук по &#8470; замовлення або по імені та прізвищу</label>
            <input onChange={(e) => search(e.target.value)}
            name='search' type='text' placeholder="Пошук" tabIndex='0'/>
          </div>
          <div className={styles.filterWrapper}>
            <label>Статус</label>
            <select
              onChange={e => {
                setStatusFilter(e.target.value);
                setRefreshOrders(true);
              }}
              defaultValue={null}
              className={styles.select}
            >
              <option>Усі замовлення</option>
              <option value='specified'>Уточнюються</option>
              <option value='processed'>В обробці</option>
              <option value='send'>Відправлені</option>
              <option value='completed'>Виконані</option>
              <option value='abolition'>Відмінити</option>
            </select>
            {/* <Button variant='dark' className={styles.refresh} onClick={() => setRefreshOrders(true)} >Обновити</Button> */}
          </div>
      </div>

      {ordersLoading ? <Loader /> : <ul>{ordersList}</ul>}
      <Pagination perPage={perPage} startPage={startPage} setPage={setStartPage} productsQuantity={ordersQuantity} />

    </div>
  );
};

export default OrdersList;
