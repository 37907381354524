import React from 'react';

export const account = (color = '#797878', filled = false, width = 16, height = 16) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="currentColor" className="bi bi-card-list" stroke={color}>
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="2"
      ry="2"
      stroke={color}
      fill={filled ? color : 'none'}
      strokeWidth="0.9px"
    ></rect>
    <circle cx="3" cy="4" r="0.6" fill={color}></circle>
    <circle cx="3" cy="8" r="0.6" fill={color}></circle>
    <circle cx="3" cy="12" r="0.6" fill={color}></circle>
    <line x1="5" y1="4" x2="13" y2="4" stroke={color} strokeWidth="0.9px"></line>
    <line x1="5" y1="8" x2="13" y2="8" stroke={color} strokeWidth="0.9px"></line>
    <line x1="5" y1="12" x2="13" y2="12" stroke={color} strokeWidth="0.9px"></line>
  </svg>
);
