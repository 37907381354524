import {
  SET_CATEGORIES,
  SET_CATEGORIES_MENU,
  // SET_PRODUCTS_FILTERED,
  // SET_PRODUCTS_LOADING,
  // SET_ONE_PRODUCT,
  // SET_ONE_PRODUCT_LOADING,
  // SET_PRODUCTS_FILTER,
  // SET_PRODUCTS_QUANTITY,
  // // eslint-disable-next-line import/named
  // SET_PRODUCTS_STYLE,
} from './types';

export const saveCategoriesAction = Categories => ({ type: SET_CATEGORIES, payload: Categories });
export const saveCategoriesMenuAction = CategoriesMenu => ({ type: SET_CATEGORIES_MENU, payload: CategoriesMenu });

// export const saveProductsFilteredAction = products => ({ type: SET_PRODUCTS_FILTERED, payload: products });

// export const saveOneProductAction = product => ({ type: SET_ONE_PRODUCT, payload: product });

// export const saveProductsFilterAction = filter => ({ type: SET_PRODUCTS_FILTER, payload: filter });

// export const saveProductsQuantityAction = quantity => ({ type: SET_PRODUCTS_QUANTITY, payload: quantity });

// export const productsLoadingAction = isLoading => ({ type: SET_PRODUCTS_LOADING, payload: isLoading });

// export const oneProductLoadingAction = isLoading => ({ type: SET_ONE_PRODUCT_LOADING, payload: isLoading });

// export const changeProductsStyle = viewType => ({ type: SET_PRODUCTS_STYLE, payload: viewType });
