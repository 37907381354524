import React from 'react';
import ProductCard from './PriductsCard';

const ProductsGrid = ({ products, handleActive, handleEdit, handleDelete }) => {
    return (
        <div className="row">
            {products.length > 0 ? (
                products.map((product) => (
                    <ProductCard
                        key={product._id} // Убедитесь, что ваш продукт имеет уникальный идентификатор
                        product={product}
                        handleActive={handleActive}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                ))
            ) : (
                <div className="col-12">
                    <h5>Нет товаров для отображения</h5>
                </div>
            )}
        </div>
    );
};

export default ProductsGrid;
