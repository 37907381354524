import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import { getWishListOperation as getWishList } from "../../../store/wishList/operations";
import { getWishListSelector, wishListLoadingSelector } from "../../../store/wishList/selectors";
import "./Wishlist.scss";
import Container from '../../../components/Container/Container';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../../context/AuthContext';
import { deleteProductFromWishlist } from '../../../http/wishlistAPI';

const Wishlist = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const wishlistFromRedux = useSelector(getWishListSelector);
    const isLoading = useSelector(wishListLoadingSelector);
    const [wishlist, setWishlist] = useState([]);
    const [forceRender, setForceRender] = useState(false); // Примусовий перерендер

    // Завантаження списку з localStorage або з Redux
    useEffect(() => {
        const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        if (storedWishlist.length > 0) {
            setWishlist(storedWishlist);
        } else {
            dispatch(getWishList(auth.permissions?.admin?.access));
        }
    }, [dispatch, auth.permissions?.admin?.access]);

    // Оновлення локального сховища і стану, коли змінюється список у Redux
    useEffect(() => {
        if (wishlistFromRedux && wishlistFromRedux.products) {
            console.log('Updating localStorage with new wishlist:', wishlistFromRedux.products);
            setWishlist(wishlistFromRedux.products);
            localStorage.setItem('wishlist', JSON.stringify(wishlistFromRedux.products));
        }
    }, [wishlistFromRedux]);

    // Видалення продукту зі списку побажань
    const deleteProduct = async (id) => {
        try {
            await deleteProductFromWishlist(id); // Дочекайтеся завершення видалення з сервера
            const updatedWishlist = wishlist.filter((product) => product._id !== id);
            setWishlist(updatedWishlist);
            localStorage.setItem('wishlist', JSON.stringify(updatedWishlist)); // Оновлюємо локальне сховище
            dispatch(getWishList(auth.permissions?.admin?.access)); // Оновлюємо Redux після
            setForceRender(!forceRender); // Примусовий ререндер компоненту
        } catch (error) {
            console.error("Failed to delete product from wishlist:", error);
        }
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!wishlist || wishlist.length === 0) {
        return (
            <section>
                <BurgerMenu />
                <h2 style={{ paddingTop: 50 + 'px' }}>Всі Вподобані товари</h2>
                <p>Kількість: <span>0</span></p>
            </section>
        );
    }

    return (
        <section>
            <BurgerMenu />
            <Container>
                <h2 style={{ paddingTop: 50 + 'px' }}>Всі вподобані товари</h2>
                <p>Kількість: <span>{wishlist.length}</span></p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Назва</th>
                            <th>Ціна</th>
                            <th>Дія</th>
                        </tr>
                    </thead>
                    <tbody>
                        {wishlist.map((product, index) => (
                            <tr key={product._id}>
                                <td className={product.enabled ? "" : "bg__disabled"}>{index + 1}</td>
                                <td className={product.enabled ? "" : "bg__disabled"}>{product.name}</td>
                                <td className={product.enabled ? "" : "bg__disabled"}>{product.currentPrice}</td>
                                <td className={product.enabled ? "" : "bg__disabled"}>
                                    <button onClick={() => deleteProduct(product._id)}>del</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </section>
    );
};

export default Wishlist;
